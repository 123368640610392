import { Pagination } from 'antd'
import { FC } from 'react'
import {
  useTablePagination,
  useGetSearchResults,
} from 'pages/DataQualityPage/utils/flexibleSearch'
import styles from './SearchPagination.less'

const SearchPagination: FC = () => {
  const {
    query: { current, pageSize, total },
    setQuery,
  } = useTablePagination()

  const { isLoading } = useGetSearchResults()

  const handlePaginationChange = (pageId: number): void => {
    setQuery({ current: pageId })
  }

  if (!current || !pageSize || !total) {
    return null
  }

  return (
    <div className={styles.pagination} data-test-id="searchPagination">
      <Pagination
        current={current}
        pageSize={pageSize}
        total={total}
        onChange={handlePaginationChange}
        showSizeChanger
        onShowSizeChange={(newPage, newPageSize) => {
          setQuery({ current: newPage, pageSize: newPageSize })
        }}
        pageSizeOptions={['25', '50', '75']}
        size="small"
        disabled={isLoading}
      />
    </div>
  )
}

export default SearchPagination
