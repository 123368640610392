import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {
  // getApiAnalysisContent,
  ApiAnalysisContentRequest,
} from '@signifyd/http'

export const API_CONTENT_QUERY_KEY = 'apiContent'

interface UseGetApiAnalysisContentOptions {
  options?: Omit<
    UseQueryOptions<Record<string, unknown>, AxiosError>,
    'queryKey' | 'queryFn'
  >
}

// TODO: Remove once HTTP calls implemented
const getMockedApiContent = async ({
  teamId: _teamId,
  orderId,
  apiName: _apiName,
  contentType: _contentType,
}: ApiAnalysisContentRequest): Promise<Record<string, unknown>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          card: {
            cardHolderName: null,
            bin: null,
            last4: null,
            expiryMonth: null,
            expiryYear: null,
            billingAddress: {
              streetAddress: null,
              unit: null,
              city: null,
              provinceCode: null,
              postalCode: null,
              countryCode: null,
              latitude: null,
              longitude: null,
            },
          },
          purchase: {
            browserIpAddress: null,
            shipments: [],
            products: [],
            travelProduct: null,
            eventTicketingProduct: null,
            eventTicketingProducts: [],
            createdAt: null,
            paymentGateway: null,
            paymentMethod: null,
            transactionId: null,
            currency: 'USD',
            avsResponseCode: null,
            cvvResponseCode: null,
            orderChannel: null,
            receivedBy: null,
            orderId,
            totalPrice: 183.86,
            giftCardAmount: null,
            creditCardAmount: null,
            recurring: null,
            orderOutcome: null,
            platformOrderId: 'platformOrderId',
            checkoutToken: 'token',
            orderSessionId: null,
            discountCodes: [],
            transactionCurrency: null,
            transactionTotalAmount: null,
          },
          recipients: [],
          userAccount: {
            email: null,
            username: null,
            phone: null,
            createdDate: null,
            accountNumber: null,
            lastOrderId: null,
            aggregateOrderCount: null,
            aggregateOrderDollars: null,
            lastUpdateDate: null,
            passwordLastUpdateDate: null,
            emailLastUpdateDate: null,
            rating: null,
            savedPayments: [],
          },
          platformAndClient: null,
          deviceFingerprints: [],
          tags: [],
          transactions: [],
          sellers: [],
          decisionRequest: null,
          decisionMechanism: 'SIGNIFYD_RECOMMENDATION',
          historicalIngestion: null,
          policy: {
            name: 'POST_AUTH',
            checkpoint: null,
          },
          customerOrderRecommendation: null,
          additionalEvalRequests: [],
          path: null,
          memberships: [],
          appliedCreateRequestTriggers: [],
          seller: {
            name: null,
            email: null,
            username: null,
            phone: null,
            domain: null,
            createdDate: null,
            lastUpdateDate: null,
            accountNumber: null,
            aggregateOrderCount: null,
            aggregateOrderDollars: null,
            shipFromAddress: {
              streetAddress: null,
              unit: null,
              city: null,
              provinceCode: null,
              postalCode: null,
              countryCode: null,
              latitude: null,
              longitude: null,
            },
            corporateAddress: {
              streetAddress: null,
              unit: null,
              city: null,
              provinceCode: null,
              postalCode: null,
              countryCode: null,
              latitude: null,
              longitude: null,
            },
            onboardingIpAddress: null,
            onboardingEmail: null,
            parentEntity: null,
            sellerId: null,
            tags: [],
            rating: null,
          },
          createdAtInstant: '2022-05-18T20:58:42Z',
          customerSubmitForGuaranteeIndicator: null,
        },
      })
    }, 1000)
  })
}

interface ApiAnalysisContentParams
  extends Omit<ApiAnalysisContentRequest, 'apiName'> {
  apiName: string | null
}

export const useApiAnalysisContent = (
  {
    teamId,
    orderId,
    apiName,
    contentType,
    apiCallNumber,
  }: Partial<ApiAnalysisContentParams>,
  options?: UseGetApiAnalysisContentOptions
): UseQueryResult<Record<string, unknown>, AxiosError> => {
  const { t } = useTranslation()

  return useQuery({
    queryKey: [
      API_CONTENT_QUERY_KEY,
      teamId,
      orderId,
      apiName,
      contentType,
      apiCallNumber,
    ],
    queryFn: () =>
      getMockedApiContent({
        teamId: teamId!,
        orderId: orderId!,
        apiName: apiName!,
        contentType: contentType!,
        apiCallNumber,
      }),
    onError: async (e) => {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        await message.error(t('jsonViewerPage.notFound'))
      } else {
        await message.error(t('jsonViewerPage.somethingWrong'))
      }
    },
    ...options,
    enabled: !!teamId && !!orderId && !!apiName && !!contentType,
  })
}
