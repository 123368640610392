import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  IntegrationAnalysisResponse,
  IntegrationAnalysisSearchRequest,
  getIntegrationAnalysis,
} from '@signifyd/http'
import {
  createIntegrationAnalysisSearchRequest,
  SearchKeys,
} from '@signifyd/utils'
import { useGlobalFilterJoinType } from './useGlobalFilterJoinType'
import { useTablePagination } from './useTablePagination'
import { useTableQueryParams } from './useTableQueryParams'
import { useTableSorting } from './useTableSorting'

export type SearchResults = IntegrationAnalysisResponse & {
  searchRequest: Partial<IntegrationAnalysisSearchRequest>
}

export type UseGetSearchResultsResponse = UseQueryResult<SearchResults>

const useGetSearchResults = (): UseGetSearchResultsResponse => {
  const {
    query: { general, ...restFilters },
  } = useTableQueryParams()

  const filters = {
    ...restFilters,
    general: general ?? undefined,
  }

  const {
    query: { current, pageSize },
    setQuery: setPagination,
  } = useTablePagination()

  const {
    query: { sortOrder, orderBy },
  } = useTableSorting()

  const { joinType } = useGlobalFilterJoinType()

  // As the user sorts & filters, it will update the query string which will cascade to this query and update search results
  return useQuery(
    ['searchResults', filters, current, pageSize, sortOrder, orderBy, joinType],
    async () => {
      const searchRequest = createIntegrationAnalysisSearchRequest({
        filters,
        pagination: {
          currentPage: current,
          size: pageSize,
        },
        sort: {
          by: orderBy as SearchKeys,
          order: sortOrder!,
        },
        joinType: joinType!,
      })

      const { data: search } = await getIntegrationAnalysis(searchRequest)

      setPagination({ total: search.totalResults })

      return {
        ...search,
        searchRequest,
      }
    },
    {
      enabled:
        current > 0 &&
        !!orderBy &&
        !!sortOrder &&
        !!joinType &&
        !!filters.teamId?.length,
      onError: (error) => {
        console.error('Error getting search results')
        console.error(error)

        return null
      },
      retry: 0,
      keepPreviousData: true,
    }
  )
}

export default useGetSearchResults
