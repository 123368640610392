import { Input, Button, Alert } from 'antd'
import { FC, useState, ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, P, Space } from '@signifyd/components'
import { UserTeam } from '@signifyd/http'
import useDeleteTeam from 'core/queries/useDeleteTeam'
import styles from './DeleteTeamContainer.less'

type Props = { selectedTeams: Array<string> } & Pick<
  UserTeam,
  'teamName' | 'teamId'
>

const DeleteTeamContainer: FC<Props> = ({ teamId, teamName }) => {
  const { t } = useTranslation()
  const [allowDelete, setAllowDelete] = useState(false)
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)

  const deleteTeam = useDeleteTeam()

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === teamName) {
      setAllowDelete(true)
    } else {
      setAllowDelete(false)
    }
  }

  const handleDeleteSubmit = async (): Promise<void> => {
    setAllowDelete(false)
    setDeleteButtonLoading(true)

    await deleteTeam.mutateAsync({ teamId, teamName })
  }

  return (
    <section className={styles.container} data-test-id="delete-team-tab">
      <Alert
        type="error"
        showIcon
        message={t('settingsPage.deleteTeam.alert')}
      />
      <Space size="lg" />
      <Text size="lg">
        {t('settingsPage.deleteTeam.title')} {teamName}
      </Text>
      <Space size="sm" />

      <P>{t('settingsPage.deleteTeam.description1')}</P>
      <P>{t('settingsPage.deleteTeam.description2')}</P>
      <P>{t('settingsPage.deleteTeam.description3')}</P>
      <Space size="sm" />

      <div className={styles.buttonContainer}>
        <Input
          placeholder={t('settingsPage.deleteTeam.placeholder')}
          onChange={handleInputChange}
          className={styles.input}
        />
        <Button
          type={!allowDelete ? 'primary' : undefined}
          danger={allowDelete}
          disabled={!allowDelete}
          loading={deleteButtonLoading}
          onClick={handleDeleteSubmit}
          data-test-id="deleteTeamButton"
        >
          {t('settingsPage.deleteTeam.deleteButton')}
        </Button>
      </div>
    </section>
  )
}

export default DeleteTeamContainer
