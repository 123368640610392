import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { ApiAnalysisWebhook, EventAnalysisGroup } from '@signifyd/http'
import styles from './SideBar.less'
import SideBarApiGroup from '../SideBarApiGroup/SideBarApiGroup'
import SideBarWebhookGroup from '../SideBarWebhookGroup/SideBarWebhookGroup'

interface Props {
  checkoutId?: string
  groups: Array<EventAnalysisGroup>
  webhooks: Array<ApiAnalysisWebhook>
}

const SideBar: FC<Props> = ({ checkoutId, groups, webhooks }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage',
  })

  return (
    <Flex vertical gap={1}>
      {checkoutId && (
        <Flex vertical className={styles.checkoutId}>
          <Text size="xs">{t('checkoutIdLabel')}</Text>
          <Text size="xs">{checkoutId}</Text>
        </Flex>
      )}
      {groups.map(({ groupLabel, apis }) => (
        <SideBarApiGroup key={groupLabel} label={groupLabel} apis={apis} />
      ))}
      {webhooks && webhooks.length > 0 && (
        <SideBarWebhookGroup webhooks={webhooks} />
      )}
    </Flex>
  )
}

export default SideBar
