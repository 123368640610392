import { Form as AntForm } from '@ant-design/compatible'
import { Button } from 'antd'
import { FormikContext, useFormik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Text } from '@signifyd/components'
import { CreatePaymentGateway } from '@signifyd/http'
import Form, { FormItem, Input } from 'core/components/Form'
import styles from './AuthNetForm.less'

export type AuthNetCredentials = Pick<
  CreatePaymentGateway,
  'loginId' | 'loginPassword'
>

const validationSchema = yup.object({
  loginId: yup.string().required('API Login ID is required'),
  loginPassword: yup.string().required('Transaction Key is required'),
})

interface Props {
  onSubmit: (details: AuthNetCredentials) => void
  gatewaySubmitStatus: { loading: boolean }
}

const AuthNetForm: FC<Props> = ({ onSubmit, gatewaySubmitStatus }) => {
  const { t } = useTranslation()

  const authNetFormik = useFormik<AuthNetCredentials>({
    initialValues: {
      loginId: '',
      loginPassword: '',
    },
    onSubmit,
    validateOnMount: true,
    validationSchema,
  })

  return (
    <FormikContext.Provider value={authNetFormik}>
      <Form layout="inline">
        <FormItem name="loginId">
          <Text size="sm" block className={styles.label}>
            {t('settingsPage.integrationsContainer.authDotNet.loginId')}
          </Text>
          <Input
            name="loginId"
            type="password"
            placeholder={t(
              'settingsPage.integrationsContainer.authDotNet.loginIdPlaceholder'
            )}
          />
        </FormItem>

        <FormItem name="loginPassword">
          <Text size="sm" block className={styles.label}>
            {t('settingsPage.integrationsContainer.authDotNet.transactionKey')}
          </Text>
          <Input
            name="loginPassword"
            type="password"
            placeholder={t(
              'settingsPage.integrationsContainer.authDotNet.transactionKeyPlaceholder'
            )}
          />
        </FormItem>

        <AntForm.Item className={styles.buttonContainer}>
          <Button
            type="primary"
            onClick={authNetFormik.submitForm}
            disabled={gatewaySubmitStatus.loading || !authNetFormik.isValid}
            data-test-id="authDotNetsubmitButton"
          >
            {t('settingsPage.integrationsContainer.authDotNet.submitButton')}
          </Button>
        </AntForm.Item>
      </Form>
    </FormikContext.Provider>
  )
}

export default AuthNetForm
