import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { AllWebhookData } from '@signifyd/http'
import LoadingSpinner from 'core/components/LoadingSpinner'
import { ColumnProps } from 'antd/lib/table'
import { WebhooksStatus } from '../../WebhooksContainer'
import { CopyDestinationButton } from '../CopyDestinationButton/CopyDestinationButton'
import DeleteWebhookPopover from '../DeleteWebhookPopover'
import styles from './WebhooksContentDisplay.less'

interface Props {
  teamId: number
  webhookHTTPStatus: WebhooksStatus
  webhooks: Array<AllWebhookData>
}

const WebhooksContentDisplay: React.FC<Props> = ({
  teamId,
  webhookHTTPStatus,
  webhooks,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'settingsPage.webhooksContainer',
  })

  const columnConfig: Array<ColumnProps<AllWebhookData>> = [
    {
      title: t('table.columnTitles.url'),
      width: '30%',
      dataIndex: 'url',
      render: (text) => <CopyDestinationButton text={text} />,
    },
    {
      title: t('table.columnTitles.apiVersion'),
      width: '5%',
      dataIndex: 'apiVersion',
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: t('table.columnTitles.eventDisplayText'),
      width: '20%',
      dataIndex: 'topic',
      render: (text) => <Text>{text}</Text>,
    },
    {
      fixed: 'right',
      dataIndex: 'id',
      render: (_text, record) => (
        <DeleteWebhookPopover teamId={teamId} webhook={record} />
      ),
    },
  ]
  // { Disabled until backend fix bug
  //   width: '25%',
  //   title: t('table.columnTitles.testResult'),
  //   render: (_text, record) => <WebhookTestPanel webhook={record} />,
  // },
  if (webhookHTTPStatus.loading) {
    return <LoadingSpinner />
  }

  if (webhooks?.length === 0) {
    return (
      <Text
        size="sm"
        data-test-id="noWebhooksPlaceholder"
        className={styles.noWebhooksPlaceholder}
      >
        {t('noWebhooksPlaceholder')}
      </Text>
    )
  }

  return (
    <Table
      columns={columnConfig}
      dataSource={webhooks}
      pagination={false}
      rowKey={(item) => item.id.toString()}
    />
  )
}

export default WebhooksContentDisplay
