import Icon from '@ant-design/icons'
import { Card, Descriptions, Flex, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { spacingMD } from '@signifyd/ant'
import {
  BookIcon,
  MonitorIcon,
  QuestionCircleIcon,
  SettingsIcon,
  Space,
  useIsThirdGen,
} from '@signifyd/components'
import FormatDate from 'core/components/FormatDate'
import { EnhancedUserTeam } from 'core/types'
import IconButton from 'pages/TeamsPage/components/TeamCard/IconButton/IconButton'
import CopyIcon from './CopyIcon/CopyIcon'
import { TEAMS_AUTO_SUBMIT_FOR_GUARANTEE_MAPPING } from './TeamCard.config'
import styles from './TeamCard.less'
import TeamDescriptionContent from './TeamDescriptionContent'
import TeamStatusTag from './TeamStatusTag'

const { Meta } = Card
const { Item: DescriptionItem } = Descriptions

interface Props {
  team: EnhancedUserTeam
  viewResources: () => void
  selectedTeams: string
}

const TeamCard: FC<Props> = ({ team, viewResources, selectedTeams }) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const teamPolicyForTooltip =
    (team.policy?.policy &&
      TEAMS_AUTO_SUBMIT_FOR_GUARANTEE_MAPPING[team.policy.policy]) ||
    TEAMS_AUTO_SUBMIT_FOR_GUARANTEE_MAPPING.CUSTOM

  return (
    <Card
      data-test-id={`${team.teamName}TeamCard`}
      actions={[
        <Tooltip
          title={t('teamsPage.teamCard.actionTitles.settings')}
          key="settings"
        >
          <Link to={`/settings/${team.teamId}/details?teamId=${selectedTeams}`}>
            <IconButton
              Icon={SettingsIcon}
              size="large"
              data-test-id={`${team.teamName}SettingsButton`}
            />
          </Link>
        </Tooltip>,
        <Tooltip
          title={t('teamsPage.teamCard.actionTitles.resources')}
          key="resources"
        >
          <IconButton
            onClick={viewResources}
            Icon={BookIcon}
            size="large"
            data-test-id={`${team.teamName}ViewResourcesButton`}
          />
        </Tooltip>,
        <Tooltip
          title={t('teamsPage.teamCard.actionTitles.monitor')}
          key="monitor"
        >
          <Link to={`/data-quality?teamId=${team.teamId}`}>
            <IconButton
              Icon={MonitorIcon}
              size="large"
              data-test-id={`${team.teamName}DataQualityButton`}
            />
          </Link>
        </Tooltip>,
      ]}
    >
      <Meta
        title={
          <Flex align="center" gap={spacingMD} wrap>
            <span className={styles.titleText}>
              {team?.onlineStore?.storeName}
            </span>
            <TeamStatusTag team={team} />
          </Flex>
        }
        description={
          team.lastCreatedAt ? (
            <>
              <span>{t('teamsPage.teamCard.lastCase')}</span>
              <FormatDate
                UTCTimestamp={team.lastCreatedAt}
                format="M/D/YYYY h:mmA"
                showTimeZoneAbbr
              />
            </>
          ) : (
            t('teamsPage.teamCard.noOrders')
          )
        }
      />
      <Space size="lg" />

      {isThirdGen ? (
        <Descriptions colon={false} column={1} size="small">
          <DescriptionItem
            label={t('teamsPage.teamCard.descriptions.apiKey')}
            className={styles.descriptionItem}
          >
            <TeamDescriptionContent
              text={team.plainToken}
              icon={<CopyIcon team={team} />}
            />
          </DescriptionItem>
          <DescriptionItem
            label={t('teamsPage.teamCard.descriptions.autoSubmit')}
          >
            <TeamDescriptionContent
              text={teamPolicyForTooltip.value}
              icon={
                <Tooltip overlay={<span>{teamPolicyForTooltip.tooltip}</span>}>
                  <Icon component={() => <QuestionCircleIcon />} />
                </Tooltip>
              }
            />
          </DescriptionItem>
        </Descriptions>
      ) : (
        <Descriptions colon={false}>
          <DescriptionItem
            label={t('teamsPage.teamCard.descriptions.apiKey')}
            contentStyle={{ display: 'table-cell' }}
            className={styles.descriptionItem}
          >
            <TeamDescriptionContent
              text={team.plainToken}
              icon={<CopyIcon team={team} />}
            />
          </DescriptionItem>
          <DescriptionItem
            label={t('teamsPage.teamCard.descriptions.autoSubmit')}
          >
            <TeamDescriptionContent
              text={teamPolicyForTooltip.value}
              icon={
                <Tooltip overlay={<span>{teamPolicyForTooltip.tooltip}</span>}>
                  <Icon component={() => <QuestionCircleIcon />} />
                </Tooltip>
              }
            />
          </DescriptionItem>
        </Descriptions>
      )}
    </Card>
  )
}

export default TeamCard
