import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import locales from 'locales'
import moment from 'moment-timezone'
import { FC, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { useStoreState } from 'stores'
import { QueryParamProvider } from 'use-query-params'
import {
  useAppCues,
  i18nInstance,
  initLocalization,
  defaultQueryClientConfig,
} from '@signifyd/components'
import AppContainer from 'core/components/App/AppContainer'
import DevToolsFeatureFlagController from 'core/components/UserLoader/DevToolsFeatureFlagController'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
initLocalization(locales)

const App: FC = () => {
  const { currentUser, userConfigs } = useStoreState((state) => state.user)
  const userTimeZone = currentUser?.uiState?.timeZone?.name

  const user = currentUser!

  useAppCues({
    user,
    appName: 'customer-dev-tools',
    allowedBuildEnvs: ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  const queryClient = new QueryClient(defaultQueryClientConfig)

  if (!currentUser || !userConfigs) {
    return null
  }

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        {/** This app is not currently translated, but we still built in localization for when that happens */}
        <I18nextProvider i18n={i18nInstance}>
          <QueryClientProvider client={queryClient}>
            <DevToolsFeatureFlagController user={user}>
              <AppContainer user={user} userConfigs={userConfigs} />
            </DevToolsFeatureFlagController>
          </QueryClientProvider>
        </I18nextProvider>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default App
