import cx from 'classnames'
import { FC, ReactNode } from 'react'
import { StatusTypes } from 'pages/DataQualityPage/types'
import styles from './GraphCard.less'

interface Props {
  headerText?: string
  footer?: ReactNode
  footerType: StatusTypes
}

const GraphCard: FC<Props> = ({ headerText, footer, footerType, children }) => {
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>{headerText}</div>
          </div>
          <div className={styles.content}>{children}</div>
          <div className={cx(styles.footer, [styles[footerType]])}>
            {footer}
          </div>
        </div>
      </div>
    </>
  )
}

export default GraphCard
