import { FC } from 'react'
import { Trans } from 'react-i18next'
import { ExternalLink } from '@signifyd/components'

interface Props {
  // TODO FET-1850 https://signifyd.atlassian.net/browse/FET-1850
  // Type this so we get auto-complete
  i18nKey: string
  url: string
}

/**
 * Translates a localized key with an embedded external link.
 *
 * The key should look like the following: `{someKey: 'text before a link <externalLink>link text</externalLink> text after a link'`
 */
const TranslatedLink: FC<Props> = ({ i18nKey, url }) => {
  return (
    <Trans
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      i18nKey={i18nKey as any}
      components={{
        externalLink: (
          <ExternalLink url={url} target="_blank">
            Placeholder
          </ExternalLink>
        ),
      }}
    />
  )
}

export default TranslatedLink
