import { Flex, Select } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import styles from './ApiSelector.less'

export interface ApiSelectorProps {
  label: string
  options: Array<{ value: string; label: string }>
  value: string
  onChange: (value: string) => void
}

const ApiSelector: FC<ApiSelectorProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage',
  })

  const currentIndex = options.findIndex((opt) => opt.value === value)
  const isLastApiCall = currentIndex === 0
  const isFirstApiCall = currentIndex === options.length - 1
  const isEmpty = options.length === 0

  const handleNext = (): void => {
    const nextIndex = Math.max(0, currentIndex - 1)

    onChange(options[nextIndex].value)
  }

  const handlePrevious = (): void => {
    const previousIndex = Math.min(options.length - 1, currentIndex + 1)

    onChange(options[previousIndex].value)
  }

  return (
    <Flex align="flex-end" className={styles.wrapper}>
      <Flex vertical>
        <Text className={styles.apiCallSelectorLabel}>
          {t('apiCallSelector', {
            api: label,
          })}
        </Text>
        <Select
          value={value}
          onChange={onChange}
          className={styles.apiCallSelector}
          defaultValue={options[0]?.value}
          options={options}
          data-test-id="apiCallSelector"
        />
      </Flex>

      <Flex className={styles.apiCallButtons}>
        <button
          disabled={isFirstApiCall || isEmpty}
          onClick={handlePrevious}
          className={styles.button}
        >
          {t('apiPreviousButton')}
        </button>
        <button
          disabled={isLastApiCall || isEmpty}
          onClick={handleNext}
          className={styles.button}
        >
          {t('apiNextButton')}
        </button>
      </Flex>
    </Flex>
  )
}

export default ApiSelector
