import { Button, Modal, List } from 'antd'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { colorBondi } from '@signifyd/colors'
import {
  Space,
  T2,
  P,
  ResourcesIcon,
  IconProps,
  IntegrationGuideIcon,
  IntegrationSDKIcon,
  IntegrationCogIcon,
} from '@signifyd/components'
import {
  StoreData,
  TEAM_STORE_GATEWAYS,
  TEAM_STORE_PROGRAMMING_LANG,
} from '@signifyd/http'
import TranslatedLink from 'core/components/TranslatedLink/TranslatedLink'
import styles from './IntegrationResourcesModal.less'

const { Item: ListItem } = List

export interface ResourceListData {
  Icon: FC<IconProps>
  content: ReactNode
}

const getListItemsToDisplay = (
  showPHP: boolean,
  showMobile: boolean,
  showAuthorize: boolean
): Array<ResourceListData> => {
  return [
    {
      isVisible: true,
      data: {
        Icon: IntegrationGuideIcon,
        content: (
          <div>
            <P>
              <TranslatedLink
                i18nKey="teamsPage.integrationResourcesModal.resourceItems.integrationGuide"
                url="https://developer.signifyd.com/ecommerce-integration-guide/"
              />
            </P>
            <P>
              <TranslatedLink
                i18nKey="teamsPage.integrationResourcesModal.resourceItems.api"
                url="https://developer.signifyd.com/api/"
              />
            </P>
          </div>
        ),
      },
    },
    {
      isVisible: showPHP || showMobile,
      data: {
        Icon: IntegrationSDKIcon,
        content: (
          <div>
            {showPHP && (
              <P>
                <TranslatedLink
                  i18nKey="teamsPage.integrationResourcesModal.resourceItems.php"
                  url="https://developer.signifyd.com/sdks/#php"
                />
              </P>
            )}
            {showMobile && (
              <P>
                <TranslatedLink
                  i18nKey="teamsPage.integrationResourcesModal.resourceItems.mobile"
                  url="https://developer.signifyd.com/sdks/"
                />
              </P>
            )}
          </div>
        ),
      },
    },
    {
      isVisible: showAuthorize,
      data: {
        Icon: IntegrationCogIcon,
        content: (
          <P>
            <TranslatedLink
              i18nKey="teamsPage.integrationResourcesModal.resourceItems.authorizeDotNet"
              url="https://developer.signifyd.com/ecommerce-integration-guide/"
            />
          </P>
        ),
      },
    },
  ]
    .filter((item) => item.isVisible)
    .map((item) => item.data)
}

interface Props {
  dismissModal: () => void
  onlineStore: StoreData
}

const IntegrationResourcesModal: FC<Props> = ({
  dismissModal,
  onlineStore,
}) => {
  const { t } = useTranslation()
  const { programmingLanguage, paymentGateways, mobileAppPlatform } =
    onlineStore

  const hasPHP = programmingLanguage === TEAM_STORE_PROGRAMMING_LANG.PHP
  const hasMobile = !!mobileAppPlatform
  const hasAuthorizeDotNet =
    paymentGateways?.[0] === TEAM_STORE_GATEWAYS.AUTHORIZE_NET

  const listItems = getListItemsToDisplay(hasPHP, hasMobile, hasAuthorizeDotNet)

  return (
    <Modal
      open
      onCancel={dismissModal}
      onOk={dismissModal}
      title={
        <div data-test-id="integrationResourcesModal">
          <div className={styles.titleIcon}>
            <ResourcesIcon fill={colorBondi} />
          </div>
          <Space size="md" />
          <T2 className={styles.titleText}>
            {t('teamsPage.integrationResourcesModal.title')}
          </T2>
        </div>
      }
      footer={null}
      width={600}
    >
      <P className={styles.description}>
        {t('teamsPage.integrationResourcesModal.description')}
      </P>
      <List
        dataSource={listItems}
        renderItem={(item) => (
          <ListItem className={styles.listItem}>
            <div className={styles.listItemIcon}>
              <item.Icon fill={colorBondi} />
            </div>
            <div className={styles.listItemContent}>{item.content}</div>
          </ListItem>
        )}
      />
      <Space size="sm" />
      <div style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          onClick={dismissModal}
          style={{ width: '160px' }}
          data-test-id="gotItButton"
        >
          {t('teamsPage.integrationResourcesModal.dismissButton')}
        </Button>
      </div>
    </Modal>
  )
}

export default IntegrationResourcesModal
