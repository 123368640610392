import { Checkbox } from 'antd'
import { FC } from 'react'
import { ColFilterKey, COL_FILTERS } from '../../columns/ColumnFilterConfig'
import styles from './MultiSelect.less'

interface Props {
  value?: Array<string>
  onChange: (values: Array<string>) => void
  columnKey: ColFilterKey
}

const MultiSelect: FC<Props> = ({ value, onChange, columnKey }) => {
  const columnData = COL_FILTERS[columnKey]

  if (columnData.searchKey !== 'custom' || !columnData.multiselect) {
    return null
  }

  return (
    <Checkbox.Group
      options={columnData.multiselect.options()}
      value={value}
      onChange={(values) => onChange(values as Array<string>)}
      data-analytics-id={`column-filter-checkbox-input-${columnKey}`}
      data-test-id="multiSelectFilterInput"
      className={styles.container}
    />
  )
}

export default MultiSelect
