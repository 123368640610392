import { FC } from 'react'
import { UserConfigs } from '@signifyd/app-feature-configs'
import {
  NavigationBar as NavigationBarComponent,
  useUpdateUserState,
} from '@signifyd/components'
import { User } from '@signifyd/http'

interface Props {
  user: User
  userConfigs: UserConfigs
}

const NavigationBar: FC<Props> = ({ user, userConfigs }) => {
  const updateUiState = useUpdateUserState(user)

  return (
    <NavigationBarComponent
      user={user}
      userConfigs={userConfigs}
      onUpdateUserState={async (navBarCollapsed) => {
        await updateUiState.mutateAsync({ navBarCollapsed })
      }}
    />
  )
}

export default NavigationBar
