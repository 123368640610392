import moment from 'moment-timezone'
import {
  useQueryParams,
  withDefault,
  StringParam,
  DelimitedArrayParam,
} from 'use-query-params'
import {
  DecodedSearchParams,
  getMaxFlexibleSearchIsoDate,
  getMinFlexibleSearchIsoDate,
} from '@signifyd/utils'

interface UseTableQueryParams {
  query: {
    teamId: Array<string>
    rangeA: DecodedSearchParams
    rangeB: DecodedSearchParams
  }
  setQuery: (newQuery: {
    teamId?: Array<string>
    rangeA?: DecodedSearchParams
    rangeB?: DecodedSearchParams
  }) => void
  resetTableFilters: (range: 'rangeA' | 'rangeB') => void
}

const defaultDateRanges = {
  rangeA: {
    normalizedPurchaseCreatedAt: {
      min: getMinFlexibleSearchIsoDate(moment().subtract(2, 'weeks')),
      max: getMaxFlexibleSearchIsoDate(moment().subtract(1, 'week')),
    },
  },
  rangeB: {
    normalizedPurchaseCreatedAt: {
      min: getMinFlexibleSearchIsoDate(
        moment().subtract(1, 'week').add(1, 'day')
      ),
      max: getMaxFlexibleSearchIsoDate(moment()),
    },
  },
}

export const useDataComparisonQueryParams = (): UseTableQueryParams => {
  const [queryParams, setQueryParams] = useQueryParams({
    teamId: withDefault(DelimitedArrayParam, []),
    rangeA: withDefault(StringParam, ''),
    rangeB: withDefault(StringParam, ''),
  })

  const validTeamIds = queryParams.teamId.filter(
    (id) => id !== null
  ) as Array<string>

  const query = {
    teamId: validTeamIds,
    rangeA: queryParams.rangeA
      ? JSON.parse(queryParams.rangeA)
      : defaultDateRanges.rangeA,
    rangeB: queryParams.rangeB
      ? JSON.parse(queryParams.rangeB)
      : defaultDateRanges.rangeB,
  }

  const setQuery = (newQuery: {
    teamId?: Array<string>
    rangeA?: DecodedSearchParams
    rangeB?: DecodedSearchParams
  }): void => {
    setQueryParams({
      ...(newQuery.teamId !== undefined && { teamId: newQuery.teamId }),
      ...(newQuery.rangeA && { rangeA: JSON.stringify(newQuery.rangeA) }),
      ...(newQuery.rangeB && { rangeB: JSON.stringify(newQuery.rangeB) }),
    })
  }

  const resetTableFilters = (range: 'rangeA' | 'rangeB'): void => {
    const clearedFilters: Partial<DecodedSearchParams> = {}

    Object.keys(query[range]).forEach((key) => {
      clearedFilters[key as keyof DecodedSearchParams] = undefined
    })

    setQuery({
      rangeA: {
        ...clearedFilters,
      } as DecodedSearchParams,
      rangeB: {
        ...clearedFilters,
      } as DecodedSearchParams,
    })
  }

  return {
    query,
    setQuery,
    resetTableFilters,
  }
}
