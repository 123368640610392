import { TreeSelect } from 'antd'
import { useField } from 'formik'
import { FC, ReactNode } from 'react'
import { MobileAppPlatform } from '@signifyd/http'

interface Props {
  disabled?: boolean
}

const options: Array<{ title: string; value: MobileAppPlatform }> = [
  { value: 'IOS', title: 'IOS' },
  { value: 'Android', title: 'Android' },
  { value: 'null', title: 'No native mobile app' },
]

const MobileAppSelect: FC<Props> = ({ disabled }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _meta, helpers] = useField('mobileAppPlatform')

  const handleChange = (
    values: Array<MobileAppPlatform>,
    _labels: Array<ReactNode>,
    { triggerValue }: { triggerValue: string | number }
  ): void => {
    if (triggerValue === 'null') {
      helpers.setValue(['null'])
    } else {
      const filteredValues = values.filter((value) => value !== 'null')

      helpers.setValue(filteredValues)
    }
  }

  return (
    <TreeSelect<Array<MobileAppPlatform>>
      disabled={disabled}
      placeholder="Select one or more"
      treeCheckable
      treeData={options}
      {...field}
      onChange={handleChange}
    />
  )
}

export default MobileAppSelect
