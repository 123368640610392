import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colorJade, colorRed } from '@signifyd/colors'
import { ExternalLink, SigTag, Space, Text } from '@signifyd/components'
import { INTEGRATION_ANALYSIS_WEBHOOK_DELIVERY_STATUS } from '@signifyd/http'
import { WebhookEvent, isV3Webhook } from 'core/utils/apiAnalysis/utils'
import { getWebhookResponseCodeStatusText } from 'core/utils/integrationEvents/utils'
import styles from './WebhookStatusPanel.less'

interface Props {
  webhook?: WebhookEvent
}

const WebhookStatusPanel: FC<Props> = ({ webhook }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage.dataQuality',
  })

  if (!webhook) {
    return null
  }

  const isDeliverySuccess =
    webhook.lastWebhook.deliveryStatus ===
    INTEGRATION_ANALYSIS_WEBHOOK_DELIVERY_STATUS.SUCCESS

  const destinationUrlDisplay = isV3Webhook(webhook)
    ? `${t('destinationUrl')} ${webhook.destinationUrlIndex + 1}`
    : t('destinationUrl')

  return (
    <div className={styles.panel}>
      <Flex vertical>
        <Text block type="secondary" size="lg" weight="semibold">
          {webhook.webhookTopicLabel}
        </Text>

        <Text block type="secondary" size="lg" weight="semibold">
          {destinationUrlDisplay}
        </Text>
      </Flex>

      <Space size="md" />

      <ExternalLink target="_blank" url={webhook.destinationUrl}>
        <Text block className={styles.webhookUrl} size="sm">
          {webhook.destinationUrl}
        </Text>
      </ExternalLink>

      <Space size="md" />

      <Text block type="secondary" size="lg">
        {t('webhookDeliverySummary')}
      </Text>

      <Space size="md" />

      <SigTag
        color={isDeliverySuccess ? colorJade : colorRed}
        type="primary"
        className={styles.tag}
      >
        <Text size="sm" type="secondary" data-test-id="statusCodeText">
          {getWebhookResponseCodeStatusText(
            webhook.lastWebhook.responseStatusCode
          )}
        </Text>
      </SigTag>

      <Space size="md" />

      {!isDeliverySuccess && (
        <>
          <Flex
            justify="space-between"
            className={styles.webhookInfo}
            data-test-id="statusCode"
          >
            <Text type="secondary" size="sm">
              {t('webhookStatusCode')}
            </Text>

            <Text type="secondary" size="sm" weight="semibold">
              {webhook.lastWebhook.responseStatusCode}
            </Text>
          </Flex>

          <Space size="xs" />
        </>
      )}

      <Flex
        justify="space-between"
        className={styles.webhookInfo}
        data-test-id="webhookTopic"
      >
        <Text type="secondary" size="sm">
          {t('webhookSignifydTopic')}
        </Text>

        <Text type="secondary" size="sm" weight="semibold">
          {webhook.webhookTopicLabel}
        </Text>
      </Flex>

      <Space size="xs" />

      <Flex
        justify="space-between"
        className={styles.webhookInfo}
        data-test-id="hmacHeader"
      >
        <Text type="secondary" size="sm">
          {t('webhookHMACHeader')}
        </Text>

        <Text type="secondary" size="sm" weight="semibold">
          {webhook.lastWebhook.hmacHeader}
        </Text>
      </Flex>
    </div>
  )
}

export default WebhookStatusPanel
