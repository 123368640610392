import { message } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useClipboard } from 'use-clipboard-copy'
import { CopyIcon as CopyIconSvg } from '@signifyd/components'
import HoverableIcon from 'core/components/HoverableIcon'
import { EnhancedUserTeam } from 'core/types'

interface Props {
  team: EnhancedUserTeam
}

const CopyIcon: FC<Props> = ({ team }) => {
  const { t } = useTranslation()

  const clipboard = useClipboard({
    onSuccess() {
      message.success(t('teamsPage.teamCard.copySuccess'))
    },
    onError() {
      message.error(t('teamsPage.teamCard.copyFailure'))
    },
  })

  if (!clipboard.isSupported()) {
    return null
  }

  return (
    <HoverableIcon
      Icon={CopyIconSvg}
      onClick={() => clipboard.copy(team.plainToken)}
    />
  )
}

export default CopyIcon
