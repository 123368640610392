import { Flex, Layout, Divider } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { colorWhite } from '@signifyd/colors'
import {
  ExternalLink,
  getOrdersUrl,
  OrdersIcon,
  SigLogoSmall,
  Text,
} from '@signifyd/components'
import { useGetApiSummary } from 'core/queries/useGetApiSummary'
import EventsContainer from './components/EventsContainer'
import SideBar from './components/SideBar/SideBar'
import styles from './NewJSONViewerPage.less'

const { Sider, Content } = Layout

export interface JSONViewerPageParams extends Record<string, string> {
  teamId: string
  orderId: string
}

const NewJSONViewerPage: FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage.header',
  })

  const { orderId, teamId } = useParams<JSONViewerPageParams>()

  const { data: apiSummaryData, isFetching } = useGetApiSummary({
    teamId,
    orderId,
  })

  const signifydId = apiSummaryData?.signifydId

  return (
    <Layout className={styles.layout}>
      <Flex vertical gap={32} className={styles.header}>
        <Flex align="center" gap={12}>
          <SigLogoSmall className={styles.logo} fill={colorWhite} />

          <Divider type="vertical" className={styles.divider} />

          <Text className={styles.title}>{t('productName')}</Text>
        </Flex>

        <Flex justify="space-between">
          <Flex vertical gap={12}>
            <Text weight="semibold" className={styles.subTitle}>
              {t('pageTitle')}
            </Text>

            <Flex gap={32}>
              {signifydId && (
                <Text size="md" className={styles.subTitleInfo}>
                  {t('caseId', { caseId: signifydId })}
                </Text>
              )}

              <Text size="md" className={styles.subTitleInfo}>
                {t('orderId', { orderId })}
              </Text>
            </Flex>
          </Flex>

          {signifydId && (
            <Flex align="center">
              <ExternalLink
                url={`${getOrdersUrl()}/${signifydId}`}
                data-test-id="viewInConsoleLink"
              >
                <Flex gap={12} align="center">
                  <OrdersIcon
                    fill={colorWhite}
                    className={styles.viewInConsoleIcon}
                  />

                  <Text className={styles.viewInConsole}>
                    {t('viewInConsole')}
                  </Text>
                </Flex>
              </ExternalLink>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Layout>
        <Sider className={styles.sider} width={264}>
          {apiSummaryData && (
            <SideBar
              groups={apiSummaryData.eventAnalysisGroups}
              webhooks={apiSummaryData.webhooks}
            />
          )}
        </Sider>

        <Content className={styles.content}>
          {apiSummaryData && (
            <EventsContainer loading={isFetching} data={apiSummaryData} />
          )}
        </Content>
      </Layout>
    </Layout>
  )
}

export default NewJSONViewerPage
