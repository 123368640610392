import { Button } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { FilterIcon } from '@signifyd/components'
import styles from './FilterButton.less'

export interface FilterButtonProps
  extends Pick<
    React.HTMLProps<HTMLElement>,
    'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onClick'
  > {
  selected: boolean
  className?: string
  'data-test-id'?: string
}

// TODO FET-1212 https://signifyd.atlassian.net/browse/FET-1212
// Move to sig-components
const FilterButton: FC<FilterButtonProps> = ({
  selected,
  className,
  onClick,
  'data-test-id': dataTestId,
  ...eventHandlers
}) => {
  const classnames = cx([
    styles.filterIcon,
    selected && styles.filterIconSelected,
    className,
  ])

  return (
    <Button
      data-test-id={dataTestId ?? 'filterButton'}
      className={classnames}
      {...eventHandlers}
      onClick={onClick}
      ghost
    >
      <FilterIcon />
    </Button>
  )
}

export default FilterButton
