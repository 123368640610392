import { Divider, Button } from 'antd'
import cx from 'classnames'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  arrayToCommaDelimitedString,
  commaDelimitedStringToArray,
} from '@signifyd/utils'
import {
  useTableQueryParams,
  useTablePagination,
} from 'pages/DataQualityPage/utils/flexibleSearch'
import styles from './SearchColumnFilterContent.less'
import {
  ColFilterKey,
  COL_FILTERS,
  COL_FILTER_TYPES,
  FilterStateValues,
  getQueryFromFilterState,
} from '../columns/ColumnFilterConfig'
import MultiSelect from './MultiSelect/MultiSelect'
import { RadioGroupOption } from './SearchColumnFilterContent.types'
import TextInput from './TextInput/TextInput'

export interface ColumnFilterContentProps {
  size?: 'medium' | 'large'
  columnKey: ColFilterKey
  filterValue: FilterStateValues
  booleanInputOptions: Array<RadioGroupOption>
  hideActiveColumn: () => void
}

const SearchColumnFilterContent: FC<ColumnFilterContentProps> = (props) => {
  const { t } = useTranslation()

  const { setQuery } = useTableQueryParams()
  const { setQuery: setPagination } = useTablePagination()

  const { size, columnKey, filterValue, hideActiveColumn } = props
  const column = COL_FILTERS[columnKey]
  const inputType = column.TYPE

  const [internalFilterValue, setInternalFilterValue] =
    useState<FilterStateValues>(filterValue)

  const onFilterValueChange = (newFilterValue: FilterStateValues): void => {
    setInternalFilterValue(newFilterValue)
  }

  const onSubmit = (): void => {
    setPagination({ current: 1 })

    setQuery(getQueryFromFilterState(internalFilterValue, column))

    hideActiveColumn()
  }

  const handleReset = (): void => {
    setInternalFilterValue('')
  }

  return (
    <div data-test-id="searchColumnFilterContent">
      <div className={cx(styles.columnFilterContent, size && styles[size])}>
        {inputType === COL_FILTER_TYPES.TEXT && (
          <TextInput
            value={internalFilterValue as string}
            onChange={onFilterValueChange}
            onEnterPress={onSubmit}
            columnKey={columnKey}
          />
        )}
        {inputType === COL_FILTER_TYPES.MULTI_SELECT && (
          <MultiSelect
            value={commaDelimitedStringToArray(internalFilterValue as string)}
            onChange={(values: Array<string>) => {
              onFilterValueChange(arrayToCommaDelimitedString(values))
            }}
            columnKey={columnKey}
          />
        )}
      </div>
      <Divider className={styles.divider} />
      <div className={styles.actionBtns}>
        <Button
          size="small"
          type="link"
          onClick={handleReset}
          data-analytics-id={`column-filter-${columnKey}-reset-button`}
          data-test-id="resetButton"
        >
          {t('columnFilters.resetButton')}
        </Button>
        <Button
          size="small"
          type="link"
          onClick={onSubmit}
          data-analytics-id={`column-filter-${columnKey}-apply-button`}
          data-test-id="applyButton"
        >
          {t('columnFilters.applyButton')}
        </Button>
      </div>
    </div>
  )
}

export default SearchColumnFilterContent
