import { Alert, Divider, Tooltip } from 'antd'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { colorBondi, colorSlate, colorCerulean } from '@signifyd/colors'
import {
  DevToolsIcon,
  ExternalIcon,
  ExternalLink,
  QuestionCircleIcon,
  Space,
  T3,
  Text,
} from '@signifyd/components'
import { IntegrationAnalysisIncomingEvent } from '@signifyd/http'
import {
  getEventDisplayName,
  getDocumentationUrl,
  getFormattedDate,
} from 'core/utils/integrationEvents/utils'
import styles from './MissingFieldsContent.less'

interface Props {
  incomingEvent: IntegrationAnalysisIncomingEvent
  signifydId: string
}

const MissingFieldsContent: FC<Props> = ({ incomingEvent, signifydId }) => {
  const { t } = useTranslation()
  const { fieldAnalyses } = incomingEvent

  if (!fieldAnalyses.length) {
    return (
      <section
        className={styles.missingFieldInfo}
        data-test-id="missingFields-empty"
      />
    )
  }

  return (
    <section className={styles.missingFieldInfo} data-test-id="missingFields">
      <Alert
        className={styles.alert}
        data-test-id="missingFieldsAlert"
        message={
          <Trans
            i18nKey="dataQualityPage.missingFields.warning"
            count={fieldAnalyses.length}
            components={{
              bold: <Text weight="semibold" />,
            }}
          />
        }
        type="error"
      />

      <Space size="md" />

      <div className={styles.header}>
        <T3 className={styles.title}>
          {t('dataQualityPage.missingFields.eventApi', {
            api: getEventDisplayName(incomingEvent),
          })}
        </T3>
        <Link
          className={styles.viewJSON}
          data-test-id="viewJSONLink"
          target="_blank"
          to={`/json/${signifydId}`}
        >
          <Text size="md">
            <DevToolsIcon fill={colorCerulean} />{' '}
            {t('dataQualityPage.missingFields.viewEvent')}
          </Text>
        </Link>
        <ExternalLink
          className={styles.viewDocs}
          data-test-id="viewDocsLink"
          url={getDocumentationUrl(incomingEvent)}
        >
          <ExternalIcon fill={colorBondi} />{' '}
          <Text size="md">
            {t('dataQualityPage.missingFields.viewDocs', {
              api: getEventDisplayName(incomingEvent),
            })}
          </Text>
        </ExternalLink>
        <Text className={styles.apiCalls} size="sm">
          {t('dataQualityPage.missingFields.apiCalls', {
            api: getEventDisplayName(incomingEvent),
            calls: incomingEvent.eventCount,
            time: getFormattedDate(incomingEvent.lastTimestamp),
          })}
        </Text>
      </div>

      <Divider className={styles.divider} />

      <Text className={styles.missingFieldsTitle} size="xs">
        {t('dataQualityPage.missingFields.expected', {
          criticalFields: fieldAnalyses.length,
        })}
      </Text>

      <div
        className={styles.missingFieldsGrid}
        data-test-id="missingFieldsGrid"
      >
        {fieldAnalyses.map(({ issuePath, fieldDescription }) => (
          <div
            className={styles.missingField}
            data-test-id={`missingField-${issuePath}`}
            key={issuePath}
          >
            {issuePath}
            {!!fieldDescription && (
              <Tooltip placement="top" title={fieldDescription}>
                <span
                  className={styles.tooltipIcon}
                  data-test-id={`${issuePath}-tooltip`}
                >
                  <QuestionCircleIcon fill={colorSlate} />
                </span>
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default MissingFieldsContent
