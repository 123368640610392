import { Button, Divider } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from 'stores'
import {
  T2,
  T4,
  Text,
  Space,
  StoreIcon,
  MonitorIcon,
  DatabaseIcon,
} from '@signifyd/components'
import PageWrapper from 'core/components/PageWrapper'
import SplashBackground from './components/SplashBackground'
import WelcomeListItem from './components/WelcomeListItem'
import styles from './WelcomePage.less'

const WelcomePage: FC = () => {
  const { t } = useTranslation()

  const status = useStoreState((state) => state.user.UIStateHTTPStatus)
  const { updateUIState } = useStoreActions((actions) => actions.user)

  const handleDismissButtonClick = (): void => {
    updateUIState({ hasDismissedWelcomeScreen: true })
  }

  return (
    <PageWrapper>
      <section className={styles.container} data-test-id="welcome-page">
        <SplashBackground />
        <div className={styles.content}>
          <div className={styles.header}>
            <T2 className={styles.headerText}>{t('welcomePage.header')}</T2>
            <Space size="xs" />
            <Text className={styles.headerText}>
              {t('welcomePage.headerDescription')}
            </Text>
          </div>
          <Divider />
          <div className={styles.listItems}>
            <WelcomeListItem
              icon={<StoreIcon fill="white" />}
              text={t('welcomePage.list.store')}
            />
            <Space size="sm" />
            <WelcomeListItem
              icon={<MonitorIcon fill="white" />}
              text={t('welcomePage.list.dataQuality')}
            />
            <Space size="sm" />
            <WelcomeListItem
              icon={<DatabaseIcon fill="white" />}
              text={t('welcomePage.list.integration')}
            />
          </div>
          <Space size="lg" />
          <div className={styles.buttonContainer}>
            <Button
              type="primary"
              onClick={handleDismissButtonClick}
              loading={status.loading}
              block
              className={styles.getStartedButton}
            >
              {t('welcomePage.getStartedButton')}
            </Button>
          </div>
          {status.failed && (
            <>
              <T4>{t('welcomePage.errorHeader')}</T4>
              <T4>{t('welcomePage.errorBody')}</T4>
            </>
          )}
        </div>
      </section>
    </PageWrapper>
  )
}

export default WelcomePage
