import { Input as AntInput } from 'antd'
import { useField } from 'formik'
import { FC } from 'react'
import { TextAreaProps as AntTextAreaProps } from 'antd/lib/input'
import { FormikFieldProps } from '../form.types'

// Textarea gets forwarded ref when antd upgrades to v4, fix then
type LegacyTextAreaProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
}

type TextAreaProps = FormikFieldProps & AntTextAreaProps & LegacyTextAreaProps

const TextArea: FC<TextAreaProps> = (props) => {
  const { name, validate, ref, ...otherProps } = props
  const [field] = useField({ name, validate })

  return <AntInput.TextArea ref={ref} {...field} {...otherProps} />
}

export default TextArea
