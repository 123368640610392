import moment, { Moment } from 'moment-timezone'
import { SEARCH_FIELD, SearchAggregationResponse } from '@signifyd/http'
import { StatusTypes } from 'pages/DataQualityPage/types'

const fields = [
  SEARCH_FIELD.orderSessionId,
  SEARCH_FIELD.deviceFingerprintingRequested,
  SEARCH_FIELD.deviceId,
]

export const DEVICE_PROFILING_THRESHOLD_80 = 80
export const DEVICE_PROFILING_THRESHOLD_70 = 70

export const calculatePercentageThresholdByField = (
  value: number | undefined,
  totalResults: number
): number => {
  if (value === undefined) {
    return 0
  }

  const percentage = (value / totalResults) * 100
  const formattedPercentage = percentage.toFixed(2)

  return Number.isNaN(formattedPercentage) ? 0 : Number(formattedPercentage)
}

export const calculateStatus = (value: number): StatusTypes => {
  if (value >= DEVICE_PROFILING_THRESHOLD_80) {
    return 'success'
  }
  if (
    value >= DEVICE_PROFILING_THRESHOLD_70 &&
    value < DEVICE_PROFILING_THRESHOLD_80
  ) {
    return 'warning'
  }

  return 'error'
}

export const formatDate = (date: Moment | undefined): string => {
  return moment(date).format('YYYY/MM/DD')
}

export const getFieldData = (
  results: SearchAggregationResponse | undefined
): {
  [x: string]: number
} => {
  return {
    [SEARCH_FIELD.orderSessionId]:
      results?.filters[SEARCH_FIELD.orderSessionId]?.count ?? 0,
    [SEARCH_FIELD.deviceFingerprintingRequested]:
      results?.filters[SEARCH_FIELD.deviceFingerprintingRequested]?.count ?? 0,
    [SEARCH_FIELD.deviceId]:
      results?.filters[SEARCH_FIELD.deviceId]?.count ?? 0,
  }
}

export const calculateThresholdMap = (
  resultsA: SearchAggregationResponse | undefined,
  resultsB: SearchAggregationResponse | undefined
): Record<string, { threshold: number; status: StatusTypes }> => {
  const totalResultsA = resultsA?.filters.investigationId?.count ?? 0
  const totalResultsB = resultsB?.filters.investigationId?.count ?? 0

  const thresholdMap = fields.map((field) => {
    const threshold = resultsB
      ? calculatePercentageThresholdByField(
          getFieldData(resultsB)[field],
          totalResultsB
        )
      : calculatePercentageThresholdByField(
          getFieldData(resultsA)[field],
          totalResultsA
        )

    const status = calculateStatus(threshold)

    return {
      [field]: {
        threshold,
        status,
      },
    }
  })

  return thresholdMap.reduce(
    (mergedData, currentData) => ({ ...mergedData, ...currentData }),
    {}
  )
}
