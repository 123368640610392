import { Flex } from 'antd'
import { FC, ReactNode } from 'react'
import { spacingMD } from '@signifyd/ant'
import styles from './TeamDescriptionContent.less'

interface Props {
  text: string
  icon?: ReactNode
}

const TeamDescriptionContent: FC<Props> = ({ text, icon }) => {
  return (
    <Flex align="start" className={styles.wrapper} gap={spacingMD}>
      <div className={styles.text} title={text}>
        {text}
      </div>
      {icon && <div className={styles.icon}>{icon}</div>}
    </Flex>
  )
}

export default TeamDescriptionContent
