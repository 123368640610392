import { Flex } from 'antd'
import { FC } from 'react'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import { ApiSummaryResponse } from '@signifyd/http'
import { createEnumParam } from '@signifyd/utils'
import { API_TYPE } from 'core/utils/apiAnalysis/utils'
import ApiEvents from './ApiEvents'
import styles from './EventsContainer.less'
import NoEventFound from './NoEventFound'
import WebhookEvents from './WebhookEvents'

export interface EventsContainerProps {
  data?: ApiSummaryResponse
  loading: boolean
}

const EventsContainer: FC<EventsContainerProps> = ({ data, loading }) => {
  const [query] = useQueryParams({
    eventType: createEnumParam(API_TYPE, API_TYPE.API),
    apiName: StringParam,
    webhookTopic: StringParam,
    webhookSummaryIndex: NumberParam,
  })

  const { eventType, apiName, webhookTopic, webhookSummaryIndex } = query

  const isApiEvent = eventType === API_TYPE.API && !!apiName
  const isWebhookEvent = eventType === API_TYPE.WEBHOOK && !!webhookTopic

  if (loading) {
    return null
  }

  return (
    <Flex vertical className={styles.contentContainer}>
      {isApiEvent && (
        <ApiEvents data={data} apiName={apiName!} eventType={eventType} />
      )}

      {isWebhookEvent && (
        <WebhookEvents
          data={data}
          webhookTopic={webhookTopic!}
          webhookIndex={webhookSummaryIndex || 0}
          eventType={eventType}
        />
      )}

      {!isApiEvent && !isWebhookEvent && <NoEventFound />}
    </Flex>
  )
}

export default EventsContainer
