import AntIcon from '@ant-design/icons'
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { FC, useState } from 'react'
import { colorBondi, colorCerulean } from '@signifyd/colors'
import { IconProps as SigIconProps } from '@signifyd/components'

interface Props extends Pick<IconComponentProps, 'onClick'> {
  Icon: FC<SigIconProps>
}

const HoverableIcon: FC<Props> = ({ Icon, onClick }) => {
  // TODO FET-1949
  // Update sig-svg icons to work better with hovering (we should not need to use javascript to set icon color)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <span
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <AntIcon
        onClick={onClick}
        component={() => <Icon fill={isHovered ? colorCerulean : colorBondi} />}
      />
    </span>
  )
}

export default HoverableIcon
