import { useQueryClient } from '@tanstack/react-query'
import { Alert, Layout as AntLayout, Modal } from 'antd'
import cx from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useStoreState } from 'stores'
import {
  ExternalIcon,
  ExternalLink,
  getOrdersUrl,
  Layout,
  OrdersIcon,
  Text,
  T2,
} from '@signifyd/components'
import LoadingSpinner from 'core/components/LoadingSpinner'
import {
  getDocumentationUrl,
  getEventDisplayName,
  getFormattedDate,
  getWebhookTopicDisplayName,
  isApiEvent,
  isWebhookEvent,
  SelectedEvent,
} from 'core/utils/integrationEvents/utils'
import ApiEvents from './components/ApiEvents/ApiEvents'
import { ApiDataQuality, WebhookStatus } from './components/DataQuality'
import JSONViewer from './components/JSONViewer'
import RefreshButton from './components/RefreshButton'
import { useGetJson, useGetIntegrationAnalysis } from './hooks'
import styles from './JSONViewerPage.less'

const { Header } = Layout
const { Sider } = AntLayout

export type TabKeys = 'first' | 'second'

const JSONViewerPage: FC = () => {
  const queryClient = useQueryClient()
  const { investigationId } = useParams()
  const { t } = useTranslation()
  const { isAdmin } = useStoreState((state) => state.user.currentUser)!
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>()
  const [selectedTab, setSelectedTab] = useState<TabKeys>(
    isAdmin ? 'first' : 'second'
  )
  const [checkoutId, setCheckoutId] = useState<string | undefined>()

  const isSelectedEventApi = isApiEvent(selectedEvent)
  const isSelectedEventWebhook = isWebhookEvent(selectedEvent)

  const {
    data: integrationAnalysisData,
    isFetching: isFetchingIntegrationAnalysis,
    isError: isErrorIntegrationAnalysis,
  } = useGetIntegrationAnalysis(investigationId)

  const { data: jsonData, isLoading: isLoadingJson } = useGetJson(
    integrationAnalysisData?.selectedCase.signifydId,
    +investigationId!,
    isSelectedEventApi ? selectedEvent.eventType : undefined,
    selectedTab === 'first'
  )

  const { selectedCase, events } = integrationAnalysisData ?? {}

  useEffect(() => {
    if (!selectedEvent) {
      setSelectedEvent(events?.[0])
    }
  }, [events, selectedEvent])

  useEffect(() => {
    const id: string | undefined = jsonData
      ? JSON.parse(jsonData).checkoutId
      : undefined

    if (id && isSelectedEventApi && selectedEvent.eventName === 'Checkout') {
      setCheckoutId(id)
    }
  }, [isSelectedEventApi, jsonData, selectedEvent])

  if (
    isFetchingIntegrationAnalysis ||
    (!selectedCase && !isErrorIntegrationAnalysis)
  ) {
    return <LoadingSpinner darkTheme data-test-id="jsonViewerPageLoading" />
  }

  if (!selectedCase || !events || !selectedEvent) {
    return (
      <Modal
        wrapClassName="JSONViewer-modal"
        closable={false}
        open
        title={t('jsonViewerPage.errorModal.title')}
      >
        <Alert type="error" message={t('jsonViewerPage.errorModal.message')} />
      </Modal>
    )
  }

  const handleRefresh = async (): Promise<void> => {
    await queryClient.invalidateQueries(['integrationAnalysis'])
    await queryClient.invalidateQueries(['getJson'])
  }

  const getViewerText = (): string | undefined => {
    if (isSelectedEventApi) {
      return jsonData
    }

    return selectedTab === 'first'
      ? selectedEvent.lastWebhook.rawRequestBody
      : selectedEvent.lastWebhook.rawResponseBody
  }

  return (
    <Layout className={styles.layout}>
      <Header
        productName={t('jsonViewerPage.header.productName')}
        title={
          <>
            {t('jsonViewerPage.header.pageTitle')}
            <Text
              block
              className={styles.titleDetail}
              size="md"
              weight="normal"
            >
              <span className={styles.caseId}>
                {t('jsonViewerPage.header.caseId', {
                  caseId: selectedCase.signifydId,
                })}
              </span>
              <span>
                {t('jsonViewerPage.header.orderId', {
                  orderId: selectedCase.orderId,
                })}
              </span>
            </Text>
          </>
        }
        extra={
          <ExternalLink
            className={styles.viewInConsole}
            data-test-id="viewInConsoleLink"
            url={`${getOrdersUrl()}/${selectedCase.signifydId}`}
          >
            <OrdersIcon fill="#fff" />
            <Text className={styles.textColor} size="md">
              {t('jsonViewerPage.header.viewInConsole')}
            </Text>
          </ExternalLink>
        }
        innerMinWidth={null}
        innerMaxWidth={null}
        className={styles.header}
        marginBottom="none"
        theme="dark"
      />
      <div className={styles.jsonViewerPage} data-test-id="jsonViewerPage">
        <Sider className={styles.sider} width={264}>
          <ApiEvents
            checkoutId={checkoutId}
            events={events}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        </Sider>
        <div className={styles.contentContainer}>
          <div className={styles.selectedApi}>
            <ExternalLink
              data-test-id="selectedApiTitleLink"
              url={getDocumentationUrl(selectedEvent)}
            >
              <T2 className={cx(styles.apiTitle, styles.titleColor)} strong>
                {t('jsonViewerPage.api', {
                  api: getEventDisplayName(selectedEvent),
                })}
                <ExternalIcon fill="#fff" />
              </T2>
            </ExternalLink>
            <div>
              <RefreshButton handleRefresh={handleRefresh} />
              <Text className={styles.textColor} size="sm">
                {isSelectedEventApi &&
                  t('jsonViewerPage.apiCalls', {
                    api: getEventDisplayName(selectedEvent),
                    calls: selectedEvent.eventCount,
                    time: getFormattedDate(selectedEvent.lastTimestamp),
                  })}
                {isSelectedEventWebhook &&
                  t('jsonViewerPage.webhookCalls', {
                    topic: getWebhookTopicDisplayName(
                      selectedEvent.webhookTopic
                    ),
                    calls: selectedEvent.totalCount,
                    time: getFormattedDate(selectedEvent.lastWebhook.createdAt),
                  })}
              </Text>
            </div>
          </div>
          <div className={styles.content}>
            {isSelectedEventApi && (
              <ApiDataQuality fieldAnalysis={selectedEvent.fieldAnalyses} />
            )}
            {isSelectedEventWebhook && (
              <WebhookStatus webhook={selectedEvent} />
            )}
            <JSONViewer
              event={selectedEvent}
              isLoading={isSelectedEventApi ? isLoadingJson : false}
              text={getViewerText()}
              onSelectedTabChange={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JSONViewerPage
