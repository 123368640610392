import { message } from 'antd'
import { action, thunk, computed } from 'easy-peasy'
import { getUserConfigs } from '@signifyd/app-feature-configs'
import {
  getCurrentUser,
  replaceUserUIState,
  getUserUIPrefs,
} from '@signifyd/http'
import { UserModel } from './types'

const defatulHttpStatus = { loading: false, success: false, failed: false }

const userModel: UserModel = {
  currentUser: null,
  userConfigs: null,
  uiPrefs: {},
  UIStateHTTPStatus: { ...defatulHttpStatus },

  // TODO FET-1859 https://signifyd.atlassian.net/browse/FET-1859
  // uiState is not the same thing as uiState.devtools, rename
  uiState: computed((state) => {
    return state.currentUser?.uiState?.devtools
  }),

  // Actions
  setUser: action((state, payload) => {
    state.currentUser = payload
  }),

  setUserConfigs: action((state, payload) => {
    state.userConfigs = payload
  }),

  setUserUiPrefs: action((state, uiPrefs) => {
    state.uiPrefs = uiPrefs
  }),

  setUIStateHTTPStatus: action((state, update) => {
    state.UIStateHTTPStatus = { ...defatulHttpStatus, ...update }
  }),

  // HTTP Stuff
  getCurrentUser: thunk(async (actions) => {
    const {
      data: { user },
    } = await getCurrentUser()

    if (!user?.active) {
      throw new Error('User is not active')
    }

    actions.setUser(user)

    await Promise.all([
      actions.getUserConfigs({ user }),
      actions.getUserUiPrefs(user.username),
    ])
  }),

  getUserConfigs: thunk(async (actions, { user }) => {
    try {
      const userConfigs = await getUserConfigs(user, 'V1')

      actions.setUserConfigs(userConfigs)
    } catch {
      console.error('Error fetching user configs')
    }
  }),

  getUserUiPrefs: thunk((actions, username) => {
    return getUserUIPrefs(username)
      .then(({ data: prefs }) => {
        actions.setUserUiPrefs(prefs)
      })
      .catch(() => {
        message.error('Failed to get UI preferences')
      })
  }),

  updateUIState: thunk((actions, payload, { getState }) => {
    const currentUIState = getState().currentUser?.uiState
    const devtoolsUIState = { devtools: payload }
    const newUIState = { ...currentUIState, ...devtoolsUIState }
    actions.setUIStateHTTPStatus({ loading: true })

    return replaceUserUIState(newUIState)
      .then(() => actions.getCurrentUser())
      .then(() => {
        actions.setUIStateHTTPStatus({ success: true })
      })
      .catch(() => {
        actions.setUIStateHTTPStatus({ failed: true })
      })
  }),
}

export default userModel
