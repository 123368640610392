import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { UserTeam, replaceTeam } from '@signifyd/http'

const { t } = i18nInstance

interface UpdateUserTeamsArgs {
  teamName: string
  teamId: number
}

const useUpdateUserTeam = (): UseMutationResult<
  UserTeam,
  AxiosError,
  UpdateUserTeamsArgs
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ teamName, teamId }: UpdateUserTeamsArgs) => {
      const { data } = await replaceTeam({ teamName }, teamId)

      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['userTeams'])
    },
    onError: (_, { teamName }) => {
      message.error(
        t('settingsPage.editTeamContainer.errorMessage', { teamName })
      )
    },
  })
}

export default useUpdateUserTeam
