import { useFeatureFlags } from '@signifyd/components'
import { featureFlagConfig } from './DevToolsFeatureFlagController'

interface DevToolsFeatureFlags {
  isThirdGenEnabled: boolean
  isNewDevToolsEnabled: boolean
}

const useDevToolsFeatureFlags = (): DevToolsFeatureFlags => {
  const {
    'has-third-gen-ui': { isEnabledForEnvironment: isThirdGenEnabled },
    'has-new-devtools': { isEnabledForEnvironment: isNewDevToolsEnabled },
  } = useFeatureFlags(featureFlagConfig.map(({ key }) => key))

  return {
    isThirdGenEnabled,
    isNewDevToolsEnabled,
  }
}

export default useDevToolsFeatureFlags
