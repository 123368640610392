import { Flex } from 'antd'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { API_TYPE } from 'core/utils/apiAnalysis/utils'
import type { TabKeys, ViewerData } from '../../types'
import RefreshButton from '../RefreshButton'
import styles from './JSONViewer.less'
import { getJsonString, getXmlString } from './JSONViewer.utils'
import PanelTabs from './PanelTabs'

interface JSONViewerPresentationProps {
  isLoading: boolean
  isAdmin: boolean
  isSelectedEventApi: boolean
  selectedTab: TabKeys
  eventType?: API_TYPE | null
  eventQuery?: unknown
  webhookText?: string
  onTabChange: (tabKey: TabKeys) => void
  onRefresh?: () => void
}

const JSONViewerPresentation: FC<JSONViewerPresentationProps> = ({
  isLoading,
  isAdmin,
  isSelectedEventApi,
  selectedTab,
  eventType,
  eventQuery,
  webhookText,
  onTabChange,
  onRefresh,
}) => {
  const { t } = useTranslation()

  const rawText = useMemo(() => {
    if (isSelectedEventApi && eventQuery) {
      return JSON.stringify(eventQuery, null, 2)
    }

    return webhookText ?? undefined
  }, [isSelectedEventApi, eventQuery, webhookText])

  const viewerData = useMemo<ViewerData>(() => {
    if (!rawText) {
      return {
        format: 'string',
        value: t('jsonViewerPage.json.notAvailable'),
      }
    }
    const json = getJsonString(rawText)
    if (json) {
      return { format: 'json', value: json }
    }
    const xml = getXmlString(rawText)
    if (xml) {
      return { format: 'xml', value: xml }
    }

    return { format: 'string', value: rawText }
  }, [rawText, t])

  return (
    <Flex
      vertical
      className={styles.container}
      data-test-id="jsonViewerContainer"
    >
      <Flex justify="space-between">
        <Text block type="secondary" size="lg" weight="semibold">
          {t('jsonViewerPage.json.title')}
        </Text>

        {isSelectedEventApi && onRefresh && (
          <RefreshButton isLoading={isLoading} onRefresh={onRefresh} />
        )}
      </Flex>

      <PanelTabs
        selectedTab={selectedTab}
        onSelectedTabChange={onTabChange}
        isLoading={isLoading}
        viewerData={viewerData}
        showFirstTab={isSelectedEventApi ? isAdmin : true}
        type={eventType === API_TYPE.API ? API_TYPE.API : API_TYPE.WEBHOOK}
      />
    </Flex>
  )
}

export default JSONViewerPresentation
