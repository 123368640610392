import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Layout, Space, Text } from '@signifyd/components'
import { ContentWrapper } from 'core/components/PageWrapper'
import TeamForm, { useTeamFormik } from 'core/components/TeamForm'
import { navigateToTeams } from 'core/utils/teams/utils'
import useGetSelectedTeams from 'core/utils/useGetSelectedTeams'
import styles from './CreateTeamPage.less'

const NOTIFICATION_KEY = 'teamFormNotification'

const { Header, Footer } = Layout

const CreateTeamPage: FC = () => {
  const { t } = useTranslation()
  const { selectedTeams } = useGetSelectedTeams()
  const navigate = useNavigate()

  const goBackToTeamsPage = (teamId?: number): void => {
    navigateToTeams({
      navigate,
      addedId: teamId,
      selectedTeams,
    })
  }

  const teamFormik = useTeamFormik({
    onSuccess: (teamId) => goBackToTeamsPage(teamId || undefined),
  })

  const onCancel = (): void => {
    if (!teamFormik.dirty) {
      goBackToTeamsPage()
    } else {
      notification.open({
        key: NOTIFICATION_KEY,
        message: (
          <Text size="md">
            {t('createTeamPage.cancelNotification.message')}
          </Text>
        ),
        description: (
          <Text size="sm">
            {t('createTeamPage.cancelNotification.description')}
            <Space size="sm" />
          </Text>
        ),
        duration: 0,
        type: 'warning',
        btn: (
          <Button
            type="primary"
            onClick={() => {
              notification.destroy(NOTIFICATION_KEY)
              goBackToTeamsPage()
            }}
          >
            {t('createTeamPage.cancelNotification.button')}
          </Button>
        ),
        icon: (
          <ExclamationCircleOutlined
            className={styles.cancelNotificationIcon}
          />
        ),
      })
    }
  }

  return (
    <Layout innerMinWidth={null} isBelowNavBar={false}>
      <Header
        productName={t('createTeamPage.productName')}
        title={t('createTeamPage.pageTitle')}
        innerMinWidth={null}
        innerMaxWidth={null}
        className={styles.header}
        marginBottom="none"
      />
      <ContentWrapper>
        <Space size="lg" />

        <TeamForm teamFormik={teamFormik} />

        <Space size="lg" />
      </ContentWrapper>
      <Footer innerMinWidth={null} innerMaxWidth={null}>
        <Button className={styles.cancelButton} onClick={onCancel}>
          {t('createTeamPage.cancelButton')}
        </Button>
        <Button
          type="primary"
          data-test-id="createTeamButton"
          onClick={teamFormik.submitForm}
          disabled={!teamFormik.isValid}
        >
          {t('createTeamPage.submitButton')}
        </Button>
      </Footer>
    </Layout>
  )
}

export default CreateTeamPage
