import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { getAllWebhooks, AllWebhookData } from '@signifyd/http'
import getSigErrorMessage from 'core/utils/getSigErrorMessage'

export const useListWebhooks = (
  teamId: number
): UseQueryResult<Array<AllWebhookData>, AxiosError> => {
  const { t } = useTranslation()

  return useQuery<Array<AllWebhookData>, AxiosError>({
    queryKey: ['webhooks', teamId],
    queryFn: async () => {
      try {
        const { data } = await getAllWebhooks('ALL', [teamId])

        return data
      } catch (err) {
        const error =
          getSigErrorMessage(err) ??
          t('settingsPage.webhooksContainer.fallbackErrorMessage.getWebhooks')

        throw new AxiosError(error)
      }
    },
  })
}
