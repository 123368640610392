import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { useNavigate } from 'react-router'
import { i18nInstance } from '@signifyd/components'
import { deleteTeam } from '@signifyd/http'
import { navigateToTeams } from 'core/utils/teams/utils'
import useGetSelectedTeams from '../utils/useGetSelectedTeams'

const { t } = i18nInstance
const messageDuration = 5

interface DeleteTeamArgs {
  teamName: string
  teamId: number
}

const useDeleteTeam = (): UseMutationResult<void, unknown, DeleteTeamArgs> => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { selectedTeams } = useGetSelectedTeams()

  return useMutation(
    async ({ teamId }) => {
      await deleteTeam(teamId)
    },
    {
      onSuccess: async (_, { teamId, teamName }) => {
        await queryClient.invalidateQueries(['userTeams'])
        message.success(
          t('settingsPage.deleteTeam.successMessage', { teamName }),
          messageDuration
        )
        navigateToTeams({ deletedId: teamId, navigate, selectedTeams })
      },
      onError: (_, { teamName }) => {
        message.error(t('settingsPage.deleteTeam.errorMessage', { teamName }))
      },
    }
  )
}

export default useDeleteTeam
