import { useQueryClient } from '@tanstack/react-query'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreState } from 'stores'
import { StringParam, withDefault, useQueryParam } from 'use-query-params'
import { createEnumParam } from '@signifyd/utils'
import {
  API_CONTENT_QUERY_KEY,
  useApiAnalysisContent,
} from 'core/queries/useGetApiContent'
import {
  API_TYPE,
  getContentType,
  WebhookEvent,
} from 'core/utils/apiAnalysis/utils'
import type { JSONViewerPageParams, TabKeys } from '../../types'
import JSONViewerPresentation from './JSONViewerPresentation'

interface JSONViewerContainerProps {
  webhook?: WebhookEvent
  apiName?: string | null
  isLoading?: boolean
  selectedApiCall?: string
}

const JSONViewer: FC<JSONViewerContainerProps> = ({
  webhook,
  apiName,
  isLoading,
  selectedApiCall,
}) => {
  const queryClient = useQueryClient()

  const { orderId, teamId } = useParams<JSONViewerPageParams>()
  const currentUser = useStoreState((state) => state.user.currentUser)
  const isAdmin = currentUser?.isAdmin ?? false

  const [eventType] = useQueryParam('eventType', createEnumParam(API_TYPE))
  const isSelectedEventApi = eventType === API_TYPE.API && !!apiName

  const TabParam = withDefault(StringParam, isAdmin ? 'first' : 'second')
  const [selectedTab, setSelectedTab] = useQueryParam('tab', TabParam)

  const contentType = useMemo(() => {
    return getContentType(isSelectedEventApi, selectedTab as TabKeys)
  }, [isSelectedEventApi, selectedTab])

  const { data: eventQuery, isFetching: isFetchingEventQuery } =
    useApiAnalysisContent({
      teamId,
      orderId,
      apiName,
      contentType,
      apiCallNumber: selectedApiCall ? parseInt(selectedApiCall) : 0,
    })

  const handleRefreshEventQuery = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: [API_CONTENT_QUERY_KEY] })
  }

  const webhookText = useMemo(() => {
    if (!webhook) {
      return undefined
    }
    if (selectedTab === 'first') {
      return webhook.lastWebhook.rawRequestBody
    }

    return webhook.lastWebhook.rawResponseBody
  }, [webhook, selectedTab])

  return (
    <JSONViewerPresentation
      isLoading={isLoading || isFetchingEventQuery}
      isAdmin={isAdmin}
      isSelectedEventApi={isSelectedEventApi}
      selectedTab={selectedTab as TabKeys}
      onTabChange={(tabKey: TabKeys) => setSelectedTab(tabKey)}
      onRefresh={isSelectedEventApi ? handleRefreshEventQuery : undefined}
      eventQuery={isSelectedEventApi ? eventQuery : undefined}
      webhookText={isSelectedEventApi ? undefined : webhookText}
      eventType={eventType}
    />
  )
}

export default JSONViewer
