import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {
  createWebhook,
  createV3Webhook,
  WebhookData,
  WebhookDataRequest,
  WEBHOOK_REQUEST_VERSIONS,
  CreatedV3Webhook,
  AllWebhookData,
} from '@signifyd/http'
import getSigErrorMessage from 'core/utils/getSigErrorMessage'

export interface WebhookPayload {
  teamId: number
  webhook: WebhookDataRequest
}

export interface MutationPayload {
  apiVersion: WEBHOOK_REQUEST_VERSIONS
  webhookPayload: WebhookPayload
}

type CreatedWebhook = WebhookData | CreatedV3Webhook | null

export const useCreateWebhook = (
  currentTeamId: number
): UseMutationResult<CreatedWebhook, AxiosError, Partial<AllWebhookData>> => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  return useMutation<CreatedWebhook, AxiosError, Partial<AllWebhookData>>({
    mutationKey: ['createWebhook', currentTeamId],
    mutationFn: async ({ apiVersion, teamId, topic, url }) => {
      try {
        if (!apiVersion || !teamId || !topic || !url) {
          return null
        }

        if (apiVersion === WEBHOOK_REQUEST_VERSIONS.V3) {
          const { data } = await createV3Webhook(url, teamId)

          return data
        }

        const { data } = await createWebhook({ url, eventType: topic }, teamId)

        return data
      } catch (err) {
        const error =
          getSigErrorMessage(err) ??
          t('settingsPage.webhooksContainer.fallbackErrorMessage.createWebhook')

        throw new AxiosError(error)
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['webhooks', currentTeamId],
      })

      message.success(
        t('settingsPage.webhooksContainer.successMessages.createWebhook')
      )
    },
  })
}
