import { Flex } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colorWhite } from '@signifyd/colors'
import { T2, ExternalLink, ExternalIcon } from '@signifyd/components'
import { ApiSummaryResponse } from '@signifyd/http'
import { getDocumentationUrl, API_TYPE } from 'core/utils/apiAnalysis/utils'
import { getSelectWebhookEvent } from './EventsContainer.utils'
import { WebhookStatusPanel } from '../DataQuality'
import JSONViewer from '../JSONViewer'
import styles from './EventsContainer.less'
import NoEventFound from './NoEventFound'

interface WebhookEventsProps {
  data?: ApiSummaryResponse
  webhookTopic: string
  webhookIndex: number
  eventType: API_TYPE
}

const WebhookEvents: FC<WebhookEventsProps> = ({
  data,
  webhookTopic,
  webhookIndex,
  eventType,
}) => {
  const { t } = useTranslation()

  const webhookSelectedEvent = getSelectWebhookEvent({
    data: data?.webhooks,
    topic: webhookTopic,
    index: webhookIndex,
  })

  if (!webhookSelectedEvent || eventType !== API_TYPE.WEBHOOK) {
    return <NoEventFound />
  }

  return (
    <Flex data-test-id="webhookEvents" vertical>
      <Flex
        justify="space-between"
        align="center"
        className={styles.selectedApi}
      >
        <ExternalLink
          data-test-id="selectedWebhookTitleLink"
          url={getDocumentationUrl(eventType, null)}
        >
          <T2 strong className={cx(styles.apiTitle, styles.title)}>
            {t('jsonViewerPage.api', { api: 'Webhooks' })}
            <ExternalIcon fill={colorWhite} />
          </T2>
        </ExternalLink>
      </Flex>

      <Flex className={styles.content}>
        <WebhookStatusPanel webhook={webhookSelectedEvent} />

        <JSONViewer webhook={webhookSelectedEvent} />
      </Flex>
    </Flex>
  )
}

export default WebhookEvents
