import { FC, ReactNode, ComponentType } from 'react'
import { colorYonder } from '@signifyd/colors'
import {
  Space,
  T4,
  SearchResultsIcon,
  IconProps,
  useIsThirdGen,
  TextThirdGen,
  TitleThirdGen,
} from '@signifyd/components'
import styles from './NoResults.less'

interface Props {
  icon?: ComponentType<IconProps>
  title: ReactNode
  description: ReactNode
  ['data-test-id']?: string
}

const NoResults: FC<Props> = ({
  icon: Icon = SearchResultsIcon,
  title,
  description,
  'data-test-id': dataTestId = 'noResults',
}) => {
  const isThirdGen = useIsThirdGen()

  return (
    <div className={styles.wrapper} data-test-id={dataTestId}>
      <Space size="lg" />
      <Icon fill={colorYonder} className={styles.icon} />
      <Space size="md" />
      {isThirdGen ? (
        <TitleThirdGen level={3}>{title}</TitleThirdGen>
      ) : (
        <T4>{title}</T4>
      )}
      <Space size="sm" />
      {isThirdGen ? (
        <TextThirdGen>{description}</TextThirdGen>
      ) : (
        <div>{description}</div>
      )}
      <Space size="lg" />
    </div>
  )
}

export default NoResults
