import { Flex } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { Text } from '@signifyd/components'
import styles from './SideBarWebhookGroupItem.less'

export interface SideBarWebhookGroupItemProps {
  url: string
  count: number
  status: string
  topic: string
  index: number
  onClick: () => void
}

const SideBarWebhookGroupItem: FC<SideBarWebhookGroupItemProps> = ({
  url,
  count,
  status,
  topic,
  index,
  onClick,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage',
  })

  const [queryParams] = useQueryParams({
    eventType: StringParam,
    webhookTopic: StringParam,
    webhookSummaryIndex: NumberParam,
  })

  return (
    <Flex
      data-test-id={`sideBarWebhookGroupItem-${topic}`}
      className={cx(
        styles.info,
        queryParams.webhookTopic === topic &&
          queryParams.webhookSummaryIndex === index &&
          styles.active
      )}
      align="center"
      gap={10}
      component="button"
      onClick={onClick}
    >
      <span
        data-test-id={`webhookStatus${status}`}
        className={cx(styles.status, status === 'SUCCESS' && styles.success)}
      />
      <Flex vertical className={styles.webhookWrapper}>
        <Text size="sm">
          {t('dataQuality.webhookV3Display', {
            number: count,
          })}
        </Text>
        <Text size="sm" block className={styles.webhookUrl}>
          {url}
        </Text>
      </Flex>
    </Flex>
  )
}

export default SideBarWebhookGroupItem
