import { ConfigProvider, Tabs } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { API_TYPE } from 'core/utils/apiAnalysis/utils'
import type { TabKeys, ViewerData } from '../../types'
import JSONPanel from './JSONPanel'

interface PanelTabsProps {
  isLoading?: boolean
  selectedTab: TabKeys
  onSelectedTabChange: (key: TabKeys) => void
  showFirstTab: boolean
  type: API_TYPE
  viewerData: ViewerData
}

const PanelTabs: FC<PanelTabsProps> = ({
  showFirstTab,
  selectedTab,
  onSelectedTabChange,
  type,
  isLoading,
  viewerData,
}) => {
  const { t } = useTranslation()

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemColor: 'white',
            itemHoverColor: 'white',
            itemSelectedColor: 'white',
            itemActiveColor: 'white',
            inkBarColor: 'white',
          },
        },
      }}
    >
      <Tabs
        activeKey={selectedTab}
        onChange={(tab) => onSelectedTabChange(tab as TabKeys)}
        animated={false}
      >
        {showFirstTab && (
          <Tabs.TabPane
            tab={t(`jsonViewerPage.json.tabs.${type}.first`)}
            key="first"
          >
            <JSONPanel
              isLoading={isLoading}
              selectedTab="first"
              viewerData={viewerData}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={t(`jsonViewerPage.json.tabs.${type}.second`)}
          key="second"
        >
          <JSONPanel
            isLoading={isLoading}
            selectedTab="second"
            viewerData={viewerData}
          />
        </Tabs.TabPane>
      </Tabs>
    </ConfigProvider>
  )
}
export default PanelTabs
