import { ReloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MonitorIcon, P, Space } from '@signifyd/components'
import { IntegrationAnalysisOrder } from '@signifyd/http'
import PagePlaceholder from 'core/components/PagePlaceholder'
import { DEFAULT_PAGINATION_LIMIT } from 'core/constants'
import {
  useTablePagination,
  useTableQueryParams,
} from 'pages/DataQualityPage/utils/flexibleSearch'

interface Props {
  isFetching: boolean
  remove: () => void
  refetch: () => void
  investigations: Array<IntegrationAnalysisOrder>
}

const NoSearchResults: FC<Props> = ({
  isFetching,
  remove,
  refetch,
  investigations,
}) => {
  const {
    query: filters,
    resetTableFilters,
    userHasAppliedColumnFilters,
  } = useTableQueryParams()

  const { setQuery: setPaging } = useTablePagination()
  const { t } = useTranslation()

  const selectedTeam = filters.teamId?.[0]
  const hasData = !!selectedTeam && !!investigations?.length

  const handleRefreshTable = (): void => {
    remove()
    refetch()
  }

  const handleResetFilters = (): void => {
    resetTableFilters()

    setPaging({
      pageSize: DEFAULT_PAGINATION_LIMIT,
      current: 1,
    })
  }

  if (isFetching || hasData) {
    return null
  }

  if (!userHasAppliedColumnFilters) {
    return (
      <PagePlaceholder
        data-test-id="noResultsNoData"
        icon={MonitorIcon}
        title={t('dataQualityPage.placeHolders.noCases.title.noFilters')}
        description={
          <>
            <P>{t('dataQualityPage.placeHolders.noCases.topDescription')}</P>
            <Space size="sm" />
            <P>{t('dataQualityPage.placeHolders.noCases.bottomDescription')}</P>
            <Button onClick={handleRefreshTable} type="link">
              <ReloadOutlined />
              {t('dataQualityPage.refreshButton')}
            </Button>
          </>
        }
      />
    )
  }

  return (
    <PagePlaceholder
      data-test-id="noResultsHasFilters"
      icon={MonitorIcon}
      title={t('dataQualityPage.placeHolders.noCases.title.hasFilters')}
      description={
        <Button onClick={handleResetFilters} type="link">
          <ReloadOutlined />
          {t('dataQualityPage.placeHolders.noCases.resetFiltersButton')}
        </Button>
      }
    />
  )
}

export default NoSearchResults
