import cx from 'classnames'
import { Editor } from 'codemirror'
import { FC } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { useTranslation } from 'react-i18next'
import { useQueryParam } from 'use-query-params'
import { StretchToPageBottom, Text } from '@signifyd/components'
import { createEnumParam } from '@signifyd/utils'
import LoadingSpinner from 'core/components/LoadingSpinner'
import { API_TYPE } from 'core/utils/apiAnalysis/utils'
import type { TabKeys, ViewerData } from '../../types'
import styles from './JSONViewer.less'
import CopyButton from '../CopyButton'

const onJSONViewerChange = (editor: Editor): void => {
  editor.getDoc().eachLine((line) => {
    if (line.text.includes('null')) {
      editor.addLineClass(line, 'text', 'null')
    }
  })
}

interface JSONPanelProps {
  selectedTab: TabKeys
  isLoading?: boolean
  viewerData: ViewerData
}

const JSONPanel: FC<JSONPanelProps> = ({
  isLoading,
  selectedTab,
  viewerData,
}) => {
  const { t } = useTranslation()

  const [eventType] = useQueryParam('eventType', createEnumParam(API_TYPE))

  if (isLoading) {
    return (
      <StretchToPageBottom className={styles.wrapper}>
        <div className={styles.jsonViewer} data-test-id="jsonPanel">
          <LoadingSpinner data-test-id="jsonViewerPanelLoading" />
        </div>
      </StretchToPageBottom>
    )
  }

  return (
    <StretchToPageBottom className={styles.wrapper}>
      <div
        className={cx(styles.jsonViewer, {
          [styles.defaultTextColor]: viewerData?.format !== 'json',
        })}
        data-test-id="jsonPanel"
      >
        <div className={styles.top}>
          <Text className={styles.textColor} size="sm">
            {t(
              `jsonViewerPage.json.description.${eventType as API_TYPE}.${selectedTab}`
            )}
          </Text>
          <CopyButton text={viewerData?.value} />
        </div>
        <CodeMirror
          value={viewerData?.value}
          options={{
            mode: {
              name: viewerData?.format === 'json' ? 'javascript' : '',
              json: viewerData?.format === 'json',
            },
            lineNumbers: true,
            readOnly: true,
            dragDrop: false,
            viewportMargin: Infinity,
            lineWrapping: true,
          }}
          onChange={onJSONViewerChange}
        />
      </div>
    </StretchToPageBottom>
  )
}
export default JSONPanel
