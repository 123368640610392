import { Select as AntSelect } from 'antd'
import { useField } from 'formik'
import { FC } from 'react'
import { SelectProps as AntSelectProps } from 'antd/lib/select'
import { FormikFieldProps } from '../form.types'

type SelectProps = FormikFieldProps & AntSelectProps

const Select: FC<SelectProps> = (props) => {
  const { name, validate, children, ...otherProps } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _meta, { setValue }] = useField({ name, validate })

  return (
    <AntSelect
      {...field}
      {...otherProps}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(value: any) => setValue(value)}
      onBlur={field.onBlur}
    >
      {children}
    </AntSelect>
  )
}

export default Select
