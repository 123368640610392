import { Tooltip, Skeleton, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { colorJade, colorRed } from '@signifyd/colors'
import { QuestionCircleIcon, SigTag, Space, Text } from '@signifyd/components'
import { EventAnalysisGroupApi } from '@signifyd/http'
import { useGetApiAnalysis } from 'core/queries/useGetApiAnalysis'
import { getFormattedDate } from 'core/utils/apiAnalysis/utils'
import { JSONViewerPageParams } from '../../NewJSONViewerPage'
import styles from './ApiDataQualityPanel.less'
import { ApiCallSelectorOption } from '../EventsContainer/EventsContainer.utils'

interface Props {
  apiName: string
  apiEvent: EventAnalysisGroupApi
  selectedApiCallOption: ApiCallSelectorOption
}

const ApiDataQualityPanel: FC<Props> = ({
  apiName,
  selectedApiCallOption,
  apiEvent,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage.dataQuality',
  })

  const { orderId, teamId } = useParams<JSONViewerPageParams>()

  const apiCallNumber = parseInt(selectedApiCallOption.value)

  const { isFetching, data: apiAnalysisData } = useGetApiAnalysis({
    teamId,
    orderId,
    apiName,
    apiCallNumber,
  })

  const fieldAnalysis = apiAnalysisData?.apiAnalysis.fieldAnalyses || []

  const fieldStatus = fieldAnalysis.length
    ? 'missingFields'
    : 'allFieldsPresent'

  return (
    <div className={styles.panel}>
      <Flex vertical gap={32}>
        <Flex vertical>
          <Text strong className={styles.callTitle}>
            {t('numberOfCalls', {
              current: selectedApiCallOption.label,
              total: apiEvent.eventCount,
            })}
          </Text>

          <Text type="secondary">
            {apiAnalysisData &&
              getFormattedDate(apiAnalysisData.apiAnalysis.lastTimestamp)}
          </Text>
        </Flex>

        <Text block type="secondary" size="lg" weight="semibold">
          {t('fieldQualityTitle')}
        </Text>
      </Flex>

      <Space size="md" />

      {isFetching ? (
        <Skeleton active paragraph={{ rows: 5, width: 100 }} />
      ) : (
        <div
          className={styles.missingFields}
          data-test-id="dataQualityMissingFields"
        >
          <Text
            block
            type="secondary"
            size="sm"
            data-test-id={`dataQualityPanelMessage-${fieldStatus}`}
          >
            {t(`${fieldStatus}.action`)}
          </Text>

          <Space size="sm" />

          <SigTag
            color={fieldAnalysis.length ? colorRed : colorJade}
            type="primary"
            className={styles.tag}
          >
            <Text size="sm" type="secondary">
              {t(`${fieldStatus}.label`, {
                count: fieldAnalysis.length,
              })}
            </Text>
          </SigTag>

          <Space size="sm" />

          <Text
            block
            type="secondary"
            className={styles.dataQualityDescription}
            size="sm"
          >
            {t(`${fieldStatus}.description`)}
          </Text>

          <Space size={9} />

          <ul>
            {fieldAnalysis.map(({ issuePath, fieldDescription }) => (
              <li
                className={styles.datafield}
                key={issuePath}
                data-test-id={`missingFieldItem-${issuePath}`}
              >
                {issuePath}
                {fieldDescription && (
                  <Tooltip
                    placement="top"
                    title={fieldDescription}
                    overlayClassName={styles.tooltip}
                    destroyTooltipOnHide
                  >
                    <span
                      className={styles.tooltipIcon}
                      data-test-id={`${issuePath}-tooltip`}
                    >
                      <QuestionCircleIcon />
                    </span>
                  </Tooltip>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ApiDataQualityPanel
