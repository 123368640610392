import { Input } from 'antd'
import { debounce } from 'lodash'
import moment from 'moment-timezone'
import { FC, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { RangePickerValue, DatePicker, Text } from '@signifyd/components'
import { RangeSearchValue } from '@signifyd/http'
import { getCurrentDate, getDateNDaysAgo } from 'core/utils/date/dateRanges'
import { useTableQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch'
import styles from './GlobalTableFilters.less'

const { RangePicker } = DatePicker
const { Search } = Input

const GlobalTableFilters: FC = () => {
  const { query, setQuery } = useTableQueryParams()
  const { general } = query

  const [searchTerm, setSearchTerm] = useState(general ?? '')

  const { t } = useTranslation()

  const currentDate = getCurrentDate()

  const setDebouncedQuery = useRef(
    debounce((newSearchTerm: string) => {
      setQuery({ general: newSearchTerm })
    }, 500)
  ).current

  useEffect(() => {
    setSearchTerm(general ?? '')
  }, [general])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value)
    setDebouncedQuery(e.target.value)
  }

  const handleDateRangeChange = (value: RangePickerValue): void => {
    if (!value) {
      return
    }

    const [startDate, endDate] = value

    if (!startDate || !endDate) {
      setQuery({ normalizedPurchaseCreatedAt: null })

      return
    }

    const normalizedPurchaseCreatedAt = {
      min: moment(startDate).startOf('day').toISOString() as RangeSearchValue,
      max: moment(endDate).endOf('day').toISOString() as RangeSearchValue,
    }

    setQuery({ normalizedPurchaseCreatedAt })
  }

  const from = query.normalizedPurchaseCreatedAt?.min
    ? moment(query.normalizedPurchaseCreatedAt.min)
    : undefined

  const to = query.normalizedPurchaseCreatedAt?.max
    ? moment(query.normalizedPurchaseCreatedAt.max)
    : undefined

  return (
    <div className={styles.container}>
      <div className={styles.filterInput}>
        <Text size="sm">{t('dataQualityPage.search')}</Text>
        <Search
          data-test-id="search"
          value={searchTerm}
          onChange={handleSearch}
          className={styles.searchOrdersInput}
          placeholder={t('dataQualityPage.searchPlaceholder')}
          allowClear
        />
      </div>
      <div className={styles.filterInput}>
        <Text size="sm">{t('dataQualityPage.dateRangeSearch')}</Text>
        <RangePicker
          value={[from, to]}
          onChange={handleDateRangeChange}
          allowClear
          ranges={{
            [t('dataQualityPage.dateRange.presetRangeToday')]: [
              currentDate,
              currentDate,
            ],
            [t('dataQualityPage.dateRange.presetRangeYesterday')]: [
              getDateNDaysAgo(1),
              getDateNDaysAgo(1).endOf('day'),
            ],
            [t('dataQualityPage.dateRange.presetRangeSevenDays')]: [
              getDateNDaysAgo(7),
              currentDate,
            ],
            [t('dataQualityPage.dateRange.presetRangeFourteenDays')]: [
              getDateNDaysAgo(14),
              currentDate,
            ],
            [t('dataQualityPage.dateRange.presetRange30Days')]: [
              getDateNDaysAgo(30),
              currentDate,
            ],
          }}
        />
      </div>
    </div>
  )
}

export default GlobalTableFilters
