import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Row, Col, Tooltip, Button } from 'antd'
import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from 'stores'
import { Text, Space } from '@signifyd/components'
import { INTEGRATION_GATEWAY_ID, PaymentGateway } from '@signifyd/http'
import TranslatedLink from 'core/components/TranslatedLink/TranslatedLink'
import AuthNetDisableModal from './AuthNetDisableModal'
import AuthNetForm from './AuthNetForm'
import { AuthNetCredentials } from './AuthNetForm/AuthNetForm'
import AuthNetFormFeedback from './AuthNetFormFeedback'
import styles from './AuthNetIntegrationContainer.less'

interface Props {
  teamId: number
  authNetIntegration?: PaymentGateway
}

const AuthNetIntegrationContainer: FC<Props> = ({
  teamId,
  authNetIntegration,
}) => {
  const { t } = useTranslation()

  // Store State & Actions
  const gatewaySubmitStatus = useStoreState(
    (state) => state.integrations.gatewaySubmitStatus
  )

  const {
    submitGateway,
    verifyGateway,
    deleteGateway,
    resetGatewaySubmitStatus,
  } = useStoreActions((actions) => actions.integrations)

  // Local State
  const [authNetChecked, setAuthNetChecked] = useState(!!authNetIntegration)
  const [modalVisible, setModalVisible] = useState(false)
  const [editCredentialsActive, setEditCredentialsActive] = useState(false)

  // auth.net creds and submit
  const onAuthNetChange = (value: boolean): void => {
    if (!value && authNetIntegration) {
      setModalVisible(true)
    } else {
      setAuthNetChecked(value)
    }
  }

  const onSubmitCredentials = ({
    loginId,
    loginPassword,
  }: AuthNetCredentials): void => {
    submitGateway({
      loginId,
      loginPassword,
      teamId,
      typeId: INTEGRATION_GATEWAY_ID.AUTHORIZE_NET,
    })
  }

  const onUpdateCredentials = (): void => {
    setEditCredentialsActive(!editCredentialsActive)
    resetGatewaySubmitStatus()
  }

  // Modal actions
  const handleModalOk = (): void => {
    setModalVisible(false)
    setAuthNetChecked(false)

    if (authNetIntegration) {
      deleteGateway(authNetIntegration)
    }
  }

  // lifecycles
  useEffect(() => {
    // if (authNetIntegration) verifyGateway(authNetIntegration.gatewayId)
  }, [authNetIntegration, verifyGateway])

  return (
    <>
      <Text size="lg">
        {t('settingsPage.integrationsContainer.authDotNet.title')}
      </Text>
      <Space size="sm" />
      <Row justify="space-between">
        <Col span={22}>
          <Text className={styles.description} block>
            <TranslatedLink
              url="https://www.signifyd.com/resources/manual/authorize-net/"
              i18nKey="settingsPage.integrationsContainer.authDotNet.description"
            />
          </Text>
        </Col>
        <Col span={2}>
          <Tooltip
            placement="right"
            title={`Click to ${authNetChecked ? 'disable' : 'enable'}`}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={authNetChecked}
              onChange={onAuthNetChange}
              data-test-id="authDotNetSwitch"
            />
          </Tooltip>
        </Col>
      </Row>
      {authNetChecked && (
        <>
          <Space size="lg" />
          <Text size="lg" block>
            {t(
              'settingsPage.integrationsContainer.authDotNet.credentials.title'
            )}
          </Text>
          <Space size="sm" />
          {!!authNetIntegration && !editCredentialsActive && (
            <>
              <Button
                onClick={onUpdateCredentials}
                className={styles.updateButton}
              >
                {t(
                  'settingsPage.integrationsContainer.authDotNet.credentials.updateButton'
                )}
              </Button>
              <AuthNetFormFeedback
                gatewaySubmitStatus={gatewaySubmitStatus}
                hasAuthNet={!!authNetIntegration}
              />
            </>
          )}
          {!!authNetIntegration && editCredentialsActive && (
            <>
              <AuthNetForm
                gatewaySubmitStatus={gatewaySubmitStatus}
                onSubmit={onSubmitCredentials}
              />
              <Button onClick={onUpdateCredentials} type="link">
                {t(
                  'settingsPage.integrationsContainer.authDotNet.credentials.cancelButton'
                )}
              </Button>
              <AuthNetFormFeedback
                gatewaySubmitStatus={gatewaySubmitStatus}
                hasAuthNet={!!authNetIntegration}
              />
            </>
          )}
          {!authNetIntegration && (
            <>
              <AuthNetForm
                gatewaySubmitStatus={gatewaySubmitStatus}
                onSubmit={onSubmitCredentials}
              />
              <AuthNetFormFeedback
                gatewaySubmitStatus={gatewaySubmitStatus}
                hasAuthNet={!!authNetIntegration}
              />
            </>
          )}
        </>
      )}

      <AuthNetDisableModal
        modalVisible={modalVisible}
        modalOkFn={handleModalOk}
        modalCancelFn={() => setModalVisible(false)}
      />
    </>
  )
}

export default AuthNetIntegrationContainer
