import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { CalculationMethod } from 'pages/DataQualityPage/types'
import {
  calculateDiffValue,
  isImproving,
} from 'pages/DataQualityPage/utils/dataComparison/utils'

interface Props {
  valueA: number
  valueB: number
  calculationMethod: CalculationMethod
}

const ChangeIndicator: FC<Props> = ({ valueA, valueB, calculationMethod }) => {
  return (
    <>
      {isImproving(valueB, valueA) ? (
        <CaretUpOutlined />
      ) : (
        <CaretDownOutlined />
      )}
      {calculateDiffValue(valueB, valueA, calculationMethod)}
      {calculationMethod === 'percentage' && '%'}
    </>
  )
}

export default ChangeIndicator
