import xmlFormat from 'xml-formatter'

export const getJsonString = (str: string): string | undefined => {
  try {
    return JSON.stringify(JSON.parse(str), null, 2)
  } catch (error) {
    console.error('JSON parse error:', error)

    return undefined
  }
}

export const getXmlString = (str: string): string | undefined => {
  try {
    return xmlFormat(str, { throwOnFailure: true })
  } catch (error) {
    console.error('XML parse error:', error)

    return undefined
  }
}
