import { Divider } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { INTEGRATION_ANALYSIS_ORDER_CHANNEL } from '@signifyd/http'
import DeviceProfilingSummary from 'pages/DataQualityPage/components/DeviceProfilingSummary'
import { CalculationMethod } from 'pages/DataQualityPage/components/DeviceProfilingSummary/DeviceProfilingSummary'
import styles from './OrderChannel.less'

interface Props {
  calculationMethod: CalculationMethod
}

const OrderChannel: FC<Props> = ({ calculationMethod }) => {
  const { t } = useTranslation()

  return (
    <>
      {Object.keys(INTEGRATION_ANALYSIS_ORDER_CHANNEL).map(
        (orderChannelValue) => {
          const orderChannel =
            orderChannelValue as INTEGRATION_ANALYSIS_ORDER_CHANNEL

          return (
            <div key={orderChannel} className={styles.orderChannelContainer}>
              <Text weight="semibold" className={styles.orderChannelLabel}>
                {t(`dataQualityPage.orderChannels.${orderChannel}`)}
              </Text>
              <DeviceProfilingSummary
                key={orderChannel}
                orderChannel={orderChannel}
                calculationMethod={calculationMethod}
              />
              <Divider />
            </div>
          )
        }
      )}
    </>
  )
}

export default OrderChannel
