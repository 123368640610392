import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { useStoreState } from 'stores'
import { Unauthorized } from '@signifyd/components'
import { USER_ROLE, User } from '@signifyd/http'
import PageWrapper from 'core/components/PageWrapper'
import CreateTeamPage from 'pages/CreateTeamPage/CreateTeamPage'
import DataQualityPage from 'pages/DataQualityPage'
import DataQualityPageThirdGen from 'pages/DataQualityPage/DataQualityPageThirdGen'
import { DataQualityProvider } from 'pages/DataQualityPage/DataQualityProvider'
import JSONViewerPage from 'pages/JSONViewerPage'
import NewJSONViewerPage from 'pages/NewJSONViewerPage'
import ResourcesPage from 'pages/ResourcesPage'
import SettingsPage from 'pages/SettingsPage'
import TeamsPage from 'pages/TeamsPage'
import useDevToolsFeatureFlags from './core/components/UserLoader/useDevToolsFeatureFlags'
import WelcomePage from './pages/WelcomePage'

const allowedRoles = new Set([
  USER_ROLE.ADMIN,
  USER_ROLE.DEVTOOLS_BETA,
  USER_ROLE.MANUAL_REVIEWER_ADMIN,
  USER_ROLE.MANUAL_REVIEWER_LEAD,
])

interface Props {
  user: User
}

const AppRoutes: FC<Props> = ({ user }) => {
  const uiState = useStoreState((state) => state.user.uiState)

  const { isNewDevToolsEnabled, isThirdGenEnabled } = useDevToolsFeatureFlags()

  if (!user.roles.find((role) => allowedRoles.has(role))) {
    return (
      <PageWrapper>
        <Unauthorized />
      </PageWrapper>
    )
  }

  if (!uiState?.hasDismissedWelcomeScreen) {
    return <WelcomePage />
  }

  return (
    <Routes>
      {isNewDevToolsEnabled ? (
        <Route
          path="/json/team/:teamId/order/:orderId"
          element={<NewJSONViewerPage />}
        />
      ) : (
        <Route path="/json/:investigationId" element={<JSONViewerPage />} />
      )}

      <Route
        path="/data-quality"
        element={
          <DataQualityProvider>
            {isThirdGenEnabled ? (
              <DataQualityPageThirdGen view="standard" />
            ) : (
              <DataQualityPage view="standard" />
            )}
          </DataQualityProvider>
        }
      />
      <Route
        path="/data-quality/comparison"
        element={
          <DataQualityProvider>
            {isThirdGenEnabled ? (
              <DataQualityPageThirdGen view="comparison" />
            ) : (
              <DataQualityPage view="comparison" />
            )}
          </DataQualityProvider>
        }
      />

      <Route path="/settings/:teamId/:section" element={<SettingsPage />} />
      <Route path="/teams/create" element={<CreateTeamPage />} />
      <Route path="/teams" element={<TeamsPage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/resources" element={<ResourcesPage />} />

      <Route path="*" element={<Navigate to="/teams" />} />
    </Routes>
  )
}

export default AppRoutes
