import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import { getTranslatedLinks } from 'core/components/TranslatedLink/getTranslatedLinks'
import { useCreateWebhook } from 'core/queries/useCreateWebhook'
import { useListWebhooks } from 'core/queries/useListWebhooks'
import WebhookForm from './components/WebhookForm'
import WebhooksContentDisplay from './components/WebhooksContentDisplay'
import styles from './WebhooksContainer.less'

export interface WebhooksStatus {
  error?: string
  loading: boolean
}

interface Props {
  teamId: number
}

const WebhooksContainer: FC<Props> = ({ teamId }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'settingsPage.webhooksContainer',
  })

  const { data, error: getWebhooksError, isFetching } = useListWebhooks(teamId)
  const { mutate: createWebhook, error: createWebhookError } =
    useCreateWebhook(teamId)

  const errorMessage = getWebhooksError?.message || createWebhookError?.message

  const status: WebhooksStatus = { error: errorMessage, loading: isFetching }

  return (
    <section data-test-id="webhooks-tab">
      <section>
        <div className={styles.descriptionContainer}>
          <Text size="lg">{t('title')}</Text>

          <Space size="sm" />

          <Text size="md">
            <Trans
              i18nKey="settingsPage.webhooksContainer.description"
              components={getTranslatedLinks({
                v2Docs:
                  'https://developer.signifyd.com/main/v2.0/reference/webhooks',
                v3Docs:
                  'https://developer.signifyd.com/main/reference/webhookmanagement',
              })}
            />
          </Text>
        </div>

        <Space size="md" />

        <WebhookForm
          teamId={teamId}
          webhookHTTPStatus={status}
          createWebhook={createWebhook}
        />
      </section>

      <Space size="lg" />

      <section>
        <Text size="lg">{t('form.usersWebhooksTitle')}</Text>

        <Space size="md" />

        <WebhooksContentDisplay
          webhooks={data ?? []}
          teamId={teamId}
          webhookHTTPStatus={status}
        />
      </section>
    </section>
  )
}

export default WebhooksContainer
