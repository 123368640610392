import { action, thunk } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import {
  listGateways,
  createGateway,
  verifyGateway,
  deleteGateway,
} from '@signifyd/http'
import getSigErrorMessage from 'core/utils/getSigErrorMessage'
import { IntegrationsHttpStatus, IntegrationsModel } from './types'

const { t } = i18nInstance

const defaultStatus: IntegrationsHttpStatus = {
  loading: false,
  success: false,
  error: null,
}

const integrationsModel: IntegrationsModel = {
  // state
  gateways: null,
  gatewaySubmitStatus: { ...defaultStatus },

  // state setters
  setGateways: action((state, gateways) => {
    state.gateways = gateways
  }),
  setGatewaySubmitStatus: action((state, newStatus) => {
    state.gatewaySubmitStatus = { ...defaultStatus, ...newStatus }
  }),
  resetGatewaySubmitStatus: action((state) => {
    state.gatewaySubmitStatus = { ...defaultStatus }
  }),

  // HTTP calls
  getGateways: thunk((actions, teamId) =>
    listGateways(teamId)
      .then(({ data }) => {
        actions.setGateways(data.gateways)
      })
      .then(() => actions.setGatewaySubmitStatus({ success: true }))
      .catch((err) => {
        const error =
          getSigErrorMessage(err) ??
          t(
            'settingsPage.integrationsContainer.fallbackErrorMessage.getGateways'
          )

        actions.setGatewaySubmitStatus({ error })
      })
  ),
  submitGateway: thunk((actions, gateway) => {
    actions.setGatewaySubmitStatus({ loading: true })

    return createGateway(gateway)
      .then(({ data }) => actions.setGateways([data]))
      .then(() => actions.setGatewaySubmitStatus({ success: true }))
      .catch((err) => {
        const error =
          getSigErrorMessage(err) ??
          t(
            'settingsPage.integrationsContainer.fallbackErrorMessage.submitGateway'
          )

        actions.setGatewaySubmitStatus({ error })
      })
  }),
  verifyGateway: thunk((actions, gatewayId) => {
    actions.setGatewaySubmitStatus({ loading: true })

    return verifyGateway(gatewayId)
      .then(() => actions.setGatewaySubmitStatus({ success: true }))
      .catch((err) => {
        const error =
          getSigErrorMessage(err) ??
          t(
            'settingsPage.integrationsContainer.fallbackErrorMessage.verifyGateway'
          )

        actions.setGatewaySubmitStatus({ error })
      })
  }),
  deleteGateway: thunk((actions, { gatewayId, teamId }) => {
    return deleteGateway(gatewayId).then(() => {
      actions.resetGatewaySubmitStatus()

      return actions.getGateways(teamId)
    })
  }),
}

export default integrationsModel
