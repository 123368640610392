import { Breadcrumb, message, Tabs } from 'antd'
import cx from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useStoreState } from 'stores'
import { CustomerNavBar, Layout, T2 } from '@signifyd/components'
import { INTEGRATION_ANALYSIS_ORDER_CHANNEL } from '@signifyd/http'
import LoadingSpinner from 'core/components/LoadingSpinner'
import useGetUserTeams from 'core/queries/useGetUserTeams'
import useGetSelectedTeams from 'core/utils/useGetSelectedTeams'
import DataComparisonFilters from 'pages/DataQualityPage/components/DataComparisonFilters/DataComparisonFilters'
import { CalculationMethod } from 'pages/DataQualityPage/components/DeviceProfilingSummary/DeviceProfilingSummary'
import { useDataQualityContext } from 'pages/DataQualityPage/DataQualityProvider'
import type { BreadcrumbProps, TabsProps } from 'antd'
import { ContentDisplay, GlobalTableFilters, TeamFilter } from './components'
import DataComparisonTool from './components/DataComparisonTool/DataComparisonTool'
import SearchDisplay from './components/SearchDisplay/SearchDisplay'
import TabbedDisplay from './components/TabbedDisplay/TabbedDisplay'
import styles from './DataQualityPage.less'
import {
  useGetSearchResults,
  useTablePagination,
  useTableQueryParams,
} from './utils/flexibleSearch'

export type DataComparisonView = 'table' | 'orderChannel'
interface Props {
  view: 'standard' | 'comparison'
}

const DataQualityPage: FC<Props> = ({ view = 'standard' }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [selectedTab, setSelectedTab] = useState<string>(view)
  const [showDataComparisonTool, setShowDataComparisonTool] =
    useState<boolean>(true)
  const [orderChannel, setOrderChannel] = useState<
    INTEGRATION_ANALYSIS_ORDER_CHANNEL | undefined
  >()

  const [calculationMethod, setCalculationMethod] =
    useState<CalculationMethod>('percentage')

  const { currentUser: user, userConfigs } = useStoreState(
    (state) => state.user
  )

  const { query, userHasAppliedColumnFilters } = useTableQueryParams()
  const { data } = useGetSearchResults()
  const { selectedTeams } = useGetSelectedTeams()
  const { resetPagination } = useTablePagination()

  const [dataComparisonView, setDataComparisonView] =
    useState<DataComparisonView>('table')

  const { totalResults } = data ?? {}

  const { data: teams, isLoading: teamsLoading } = useGetUserTeams()

  const { t } = useTranslation()
  const { teamId: teamIds } = query

  const { setIsFilterEnabled } = useDataQualityContext()

  useEffect(() => {
    if (!teams || !teamIds || teamIds.length < 1) {
      return
    }

    const foundTeam = teams.find(({ teamId }) =>
      teamIds.includes(teamId.toString())
    )

    if (!foundTeam) {
      message.warning(t('dataQualityPage.api.teams.warning'))
      navigate('/data-quality')
    }
  }, [teamIds, teams, navigate, t])

  if (!user || !userConfigs) {
    return null
  }

  const shouldHideFilters = !teams?.length || !query.teamId?.length

  const userTeamIds = teams?.map((team) => team.teamId.toString()) ?? []
  const validTeams = selectedTeams.filter((id) => userTeamIds.includes(id))

  const activateTabs = !validTeams.length

  const getBreadcrumbItems = (): BreadcrumbProps['items'] => {
    const dataQualityPage = {
      title: t('dataQualityPage.breadcrumbs.dataQualityTable'),
      className: cx(styles.breadcrumbItem, {
        [styles.breadcrumbLinked]: dataComparisonView === 'orderChannel',
      }),
      onClick: () => {
        setDataComparisonView('table')
      },
    }
    const orderChannelPage = {
      title: t('dataQualityPage.breadcrumbs.orderChannel'),
      className: styles.breadcrumbItem,
    }

    if (dataComparisonView === 'orderChannel') {
      return [dataQualityPage, orderChannelPage]
    }

    return [dataQualityPage]
  }

  const items: TabsProps['items'] = [
    {
      key: 'standard',
      label: t('dataQualityPage.tabs.standardSearch'),
      disabled: activateTabs,
      children: (
        <TabbedDisplay teams={teams}>
          <>
            {!shouldHideFilters && (
              <SearchDisplay>
                <GlobalTableFilters />
              </SearchDisplay>
            )}
            <ContentDisplay
              hasFilters={userHasAppliedColumnFilters}
              teams={teams}
              totalRows={totalResults ?? 0}
            />
          </>
        </TabbedDisplay>
      ),
    },
    {
      key: 'comparison',
      label: t('dataQualityPage.tabs.dataComparisonTool'),
      disabled: activateTabs,
      children: (
        <TabbedDisplay teams={teams}>
          <>
            {!shouldHideFilters && (
              <SearchDisplay>
                <DataComparisonFilters
                  handleCompare={() => setShowDataComparisonTool(true)}
                  handleReset={() => {
                    setShowDataComparisonTool(false)
                    setCalculationMethod('percentage')
                  }}
                  handleOrderChannel={setOrderChannel}
                />
              </SearchDisplay>
            )}
            {showDataComparisonTool && (
              <>
                <Breadcrumb
                  className={styles.breadcrumb}
                  separator=">"
                  items={getBreadcrumbItems()}
                />
                <DataComparisonTool
                  orderChannel={orderChannel}
                  dataComparisonView={dataComparisonView}
                  setDataComparisonView={setDataComparisonView}
                  calculationMethod={calculationMethod}
                  setCalculationMethod={setCalculationMethod}
                />
              </>
            )}
          </>
        </TabbedDisplay>
      ),
    },
  ]

  const handleChange = (key: string): void => {
    setSelectedTab(key)

    setIsFilterEnabled(true)

    if (key === 'comparison') {
      setIsFilterEnabled(false)
      navigate(`/data-quality/comparison${location.search}`)
    }

    if (key === 'standard') {
      navigate(`/data-quality${location.search}`)
    }

    resetPagination()
  }

  if (teamsLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.container}>
      <Layout>
        <Layout.NavBar>
          <CustomerNavBar user={user} userConfigs={userConfigs} />
        </Layout.NavBar>
        <section
          className={styles.dataQualityPage}
          data-test-id="dataquality-page"
        >
          <div className={styles.header}>
            <T2 className={styles.title}>{t('dataQualityPage.pageTitle')}</T2>
            {!!validTeams && <TeamFilter validTeams={validTeams} />}
          </div>

          <Tabs
            defaultActiveKey={selectedTab}
            items={items}
            onChange={handleChange}
            className={styles.tabs}
            tabBarStyle={{ margin: 0 }}
          />
        </section>
      </Layout>
    </div>
  )
}

export default DataQualityPage
