import { ReloadOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Flex, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { P, Space, TextThirdGen, useIsThirdGen } from '@signifyd/components'
import { UserTeam } from '@signifyd/http'
import { useGetSearchResults } from 'pages/DataQualityPage/utils/flexibleSearch'
import { SearchResultsTable, SearchPagination } from '../SearchResultsTable'

interface Props {
  hasFilters: boolean
  teams?: Array<UserTeam>
  totalRows: number
}

const ContentDisplay: React.FC<Props> = ({ totalRows }) => {
  const queryClient = useQueryClient()
  const isThirdGen = useIsThirdGen()

  const { t } = useTranslation()

  const handleRefreshTable = async (): Promise<void> => {
    await queryClient.invalidateQueries(['searchResults'])
  }

  if (!isThirdGen) {
    return (
      <>
        <Row justify="space-between" align="middle">
          <P>{t('dataQualityPage.totalRows', { count: totalRows })}</P>
          <Button onClick={handleRefreshTable} type="link">
            <ReloadOutlined />
            {t('dataQualityPage.refreshButton')}
          </Button>
        </Row>

        <SearchResultsTable dataFetcher={useGetSearchResults} />

        <Space size="md" />

        <Row justify="center" align="middle">
          <SearchPagination />
        </Row>
      </>
    )
  }

  return (
    <>
      <Flex justify="space-between" align="center">
        <TextThirdGen paragraph>
          {t('dataQualityPage.totalRows', { count: totalRows })}
        </TextThirdGen>
        <Button onClick={handleRefreshTable} type="link">
          <ReloadOutlined />
          {t('dataQualityPage.refreshButton')}
        </Button>
      </Flex>

      <Space size="md" />

      <SearchResultsTable dataFetcher={useGetSearchResults} />

      <Space size="md" />

      <Row justify="center" align="middle">
        <SearchPagination />
      </Row>
    </>
  )
}

export default ContentDisplay
