import { FC, useEffect, useState } from 'react'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { EventAnalysisGroupApi } from '@signifyd/http'
import { createEnumParam } from '@signifyd/utils'
import { API_TYPE } from 'core/utils/apiAnalysis/utils'
import SideBarApiGroupItem from '../SideBarApiGroupItem/SideBarApiGroupItem'
import SideBarGroup from '../SideBarGroup/SideBarGroup'

interface Props {
  label: string
  apis: Array<EventAnalysisGroupApi>
}

const SideBarApiGroup: FC<Props> = ({ label, apis }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    eventType: createEnumParam(API_TYPE, API_TYPE.API),
    apiName: StringParam,
    webhookTopic: StringParam,
    webhookSummaryIndex: NumberParam,
  })

  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = (): void => {
    setIsExpanded((current) => !current)
  }

  useEffect(() => {
    if (isExpanded) {
      return
    }

    if (queryParams.apiName) {
      setIsExpanded(
        queryParams.eventType === 'api' &&
          apis.some((api) => api.apiName === queryParams.apiName)
      )
    } else if (!queryParams.webhookTopic && apis.length > 0) {
      setQueryParams({
        eventType: API_TYPE.API,
        apiName: apis[0].apiName,
        webhookTopic: null,
        webhookSummaryIndex: null,
      })
    }
  }, [queryParams, isExpanded, apis, setQueryParams])

  return (
    <SideBarGroup isExpanded={isExpanded} label={label} onClick={handleToggle}>
      {apis.map((api) => (
        <SideBarApiGroupItem key={api.apiName} api={api} />
      ))}
    </SideBarGroup>
  )
}

export default SideBarApiGroup
