import { Flex } from 'antd'
import cx from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colorWhite } from '@signifyd/colors'
import { T2, ExternalLink, ExternalIcon } from '@signifyd/components'
import { ApiSummaryResponse } from '@signifyd/http'
import { getDocumentationUrl, API_TYPE } from 'core/utils/apiAnalysis/utils'
import {
  getMatchingApiEvent,
  getApiCallSelectorOptions,
  getApiLabel,
} from './EventsContainer.utils'
import ApiSelector from '../ApiSelector'
import { ApiDataQualityPanel } from '../DataQuality'
import JSONViewer from '../JSONViewer'
import styles from './EventsContainer.less'
import NoEventFound from './NoEventFound'

interface ApiEventsProps {
  data?: ApiSummaryResponse
  apiName: string
  eventType: API_TYPE
}

const ApiEvents: FC<ApiEventsProps> = ({ data, apiName, eventType }) => {
  const { t } = useTranslation()
  const [selectedApiCall, setSelectedApiCall] = useState<string>('')

  const apiSelectedEvent = getMatchingApiEvent({
    data: data?.eventAnalysisGroups,
    apiName,
  })

  const apiCallSelectorOptions = apiSelectedEvent
    ? getApiCallSelectorOptions(
        apiSelectedEvent.eventCountStored,
        apiSelectedEvent.eventCount
      )
    : []

  const selectedApiCallOption = apiCallSelectorOptions.find(
    (opt) => opt.value === selectedApiCall
  ) || { value: '', label: '' }

  useEffect(() => {
    // When the API name or event changes, default to the last event call
    if (apiName && apiSelectedEvent?.eventCount) {
      setSelectedApiCall(apiSelectedEvent.eventCount.toString())
    }
  }, [apiName, apiSelectedEvent])

  if (!apiSelectedEvent) {
    return <NoEventFound />
  }

  return (
    <Flex data-test-id="apiEvents" vertical>
      <Flex
        justify="space-between"
        align="center"
        className={styles.selectedApi}
      >
        <ExternalLink
          data-test-id="selectedApiTitleLink"
          url={getDocumentationUrl(eventType, apiName)}
        >
          <T2 strong className={cx(styles.apiTitle, styles.title)}>
            {t('jsonViewerPage.api', {
              api: getApiLabel(apiName, data),
            })}
            <ExternalIcon fill={colorWhite} />
          </T2>
        </ExternalLink>

        <ApiSelector
          label={getApiLabel(apiName, data)}
          options={apiCallSelectorOptions}
          value={selectedApiCall}
          onChange={setSelectedApiCall}
        />
      </Flex>

      <Flex className={styles.content}>
        <ApiDataQualityPanel
          apiEvent={apiSelectedEvent}
          selectedApiCallOption={selectedApiCallOption}
          apiName={apiName}
        />

        <JSONViewer apiName={apiName} selectedApiCall={selectedApiCall} />
      </Flex>
    </Flex>
  )
}

export default ApiEvents
