import { Flex } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { Text } from '@signifyd/components'
import { EventAnalysisGroupApi } from '@signifyd/http'
import { useGetApiAnalysis } from 'core/queries/useGetApiAnalysis'
import styles from './SideBarApiGroupItem.less'

interface Props {
  api: EventAnalysisGroupApi
}

const SideBarApiGroupItem: FC<Props> = ({ api }) => {
  const { teamId, orderId } = useParams()

  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage',
  })

  const [queryParams, setQueryParams] = useQueryParams({
    eventType: StringParam,
    apiName: StringParam,
    webhookTopic: StringParam,
    webhookSummaryIndex: NumberParam,
  })

  const { data } = useGetApiAnalysis({ teamId, orderId, apiName: api.apiName })

  const missingFieldCount = data?.apiAnalysis.fieldAnalyses.length ?? 0

  const isActive = queryParams.apiName === api.apiName
  const isSuccess = missingFieldCount === 0

  const handleClick = (): void => {
    setQueryParams({
      eventType: 'api',
      apiName: api.apiName,
      webhookTopic: null,
      webhookSummaryIndex: null,
    })
  }

  return (
    <Flex
      data-test-id="sideBarApiGroupItem"
      vertical
      component="button"
      className={styles.itemWrapper}
      onClick={handleClick}
    >
      <Flex
        data-test-id="innerSideBarApiGroupItem"
        className={cx(styles.item, {
          [styles.active]: isActive,
        })}
      >
        <Text size="sm" weight="semibold">
          {api.apiLabel}
        </Text>
      </Flex>
      <Flex vertical gap={6} className={styles.info}>
        <Text size="sm">{t('callNumber', { callNumber: api.eventCount })}</Text>
        {data && (
          <Flex align="center" gap={10}>
            <span
              className={cx(styles.status, {
                [styles.success]: isSuccess,
              })}
            />
            <Text size="sm">
              {missingFieldCount === 0
                ? t('dataQuality.allFieldsPresent.label')
                : t('dataQuality.missingFields.label', {
                    count: missingFieldCount,
                  })}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default SideBarApiGroupItem
