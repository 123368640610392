import { Flex } from 'antd'
import { FC } from 'react'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { Text } from '@signifyd/components'
import { WebhookSummary } from '@signifyd/http'
import styles from './SideBarWebhookTopicItem.less'
import SideBarWebhookGroupItem from '../SideBarWebhookGroupItem/SideBarWebhookGroupItem'

export interface SideBarWebhookTopicItemProps {
  label: string
  topic: string
  summaries: Array<WebhookSummary>
}

const SideBarWebhookTopicItem: FC<SideBarWebhookTopicItemProps> = ({
  label,
  topic,
  summaries,
}) => {
  const [, setQueryParams] = useQueryParams({
    eventType: StringParam,
    apiName: StringParam,
    webhookTopic: StringParam,
    webhookSummaryIndex: NumberParam,
  })

  const handleClick = (topic: string, i: number): (() => void) => {
    return () => {
      setQueryParams({
        eventType: 'webhook',
        apiName: null,
        webhookTopic: topic,
        webhookSummaryIndex: i,
      })
    }
  }

  return (
    <Flex
      data-test-id="sideBarWebhookTopicItem"
      vertical
      className={styles.itemWrapper}
    >
      <Flex className={styles.item}>
        <Text size="sm" weight="semibold">
          {label}
        </Text>
      </Flex>
      <Flex vertical>
        {summaries.map(
          (
            { destinationUrl, totalCount, lastWebhook: { deliveryStatus } },
            i
          ) => (
            <SideBarWebhookGroupItem
              key={destinationUrl}
              count={totalCount}
              index={i}
              onClick={handleClick(topic, i)}
              status={deliveryStatus}
              topic={topic}
              url={destinationUrl}
            />
          )
        )}
      </Flex>
    </Flex>
  )
}

export default SideBarWebhookTopicItem
