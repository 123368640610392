import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  IntegrationAnalysisResponse,
  IntegrationAnalysisSearchRequest,
  SEARCH_FIELD,
  getIntegrationAnalysis,
} from '@signifyd/http'
import {
  createIntegrationAnalysisSearchRequest,
  SearchKeys,
} from '@signifyd/utils'
import { useDataComparisonQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch/useTableComparisonQueryParams'
import { useGlobalFilterJoinType } from './useGlobalFilterJoinType'
import { useTablePagination } from './useTablePagination'
import { useTableSorting } from './useTableSorting'

export type SearchResults = IntegrationAnalysisResponse & {
  searchRequest: Partial<IntegrationAnalysisSearchRequest>
}

export type UseGetSearchResultsResponse = UseQueryResult<SearchResults>

const useGetDataRangeResults = (
  rangeType: 'rangeA' | 'rangeB' = 'rangeA',
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
): UseGetSearchResultsResponse => {
  const {
    query: { teamId, [rangeType]: range },
  } = useDataComparisonQueryParams()

  const filters = {
    teamId: teamId.length > 0 ? teamId : undefined,
    normalizedPurchaseCreatedAt: range.normalizedPurchaseCreatedAt,
    [orderChannel ? SEARCH_FIELD.orderChannel : '']: [orderChannel],
  }

  const {
    query: { current, pageSize },
    setQuery: setPagination,
  } = useTablePagination()

  const {
    query: { sortOrder, orderBy },
  } = useTableSorting()

  const { joinType } = useGlobalFilterJoinType()

  // As the user sorts & filters, it will update the query string which will cascade to this query and update search results
  return useQuery(
    [
      'rangeFilterResults',
      filters,
      current,
      pageSize,
      sortOrder,
      orderBy,
      joinType,
      rangeType,
    ],
    async () => {
      const searchRequest = createIntegrationAnalysisSearchRequest({
        filters,
        pagination: {
          currentPage: current,
          size: pageSize,
        },
        sort: {
          by: orderBy as SearchKeys,
          order: sortOrder!,
        },
        joinType: joinType!,
      })

      const { data: search } = await getIntegrationAnalysis(searchRequest)

      setPagination({ total: search.totalResults })

      return {
        ...search,
        searchRequest,
      }
    },
    {
      enabled:
        current > 0 &&
        !!orderBy &&
        !!sortOrder &&
        !!joinType &&
        !!filters.teamId &&
        !!range.normalizedPurchaseCreatedAt,
      onError: (error) => {
        console.error('Error getting search results')
        console.error(error)

        return null
      },
      retry: 0,
    }
  )
}

export default useGetDataRangeResults
