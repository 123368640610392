import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { ApiAnalysisWebhook } from '@signifyd/http'
import SideBarGroup from '../SideBarGroup/SideBarGroup'
import SideBarWebhookTopicItem from '../SideBarWebhookTopicItem/SideBarWebhookTopicItem'

export interface SideBarWebhookGroupProps {
  webhooks: Array<ApiAnalysisWebhook>
}

const SideBarWebhookGroup: FC<SideBarWebhookGroupProps> = ({ webhooks }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jsonViewerPage',
  })

  const [queryParams] = useQueryParams({
    eventType: StringParam,
    apiName: StringParam,
    webhookTopic: StringParam,
    webhookSummaryIndex: NumberParam,
  })

  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = (): void => {
    setIsExpanded((current) => !current)
  }

  useEffect(() => {
    if (isExpanded) {
      return
    }

    setIsExpanded(queryParams.eventType === 'webhook')
  }, [queryParams, isExpanded])

  return (
    <SideBarGroup
      isExpanded={isExpanded}
      label={t('webhooks')}
      onClick={handleToggle}
    >
      {webhooks.map(
        ({ webhookSummaryByUrl, webhookTopic, webhookTopicLabel }) => (
          <SideBarWebhookTopicItem
            key={webhookTopic}
            label={webhookTopicLabel}
            summaries={webhookSummaryByUrl}
            topic={webhookTopic}
          />
        )
      )}
    </SideBarGroup>
  )
}

export default SideBarWebhookGroup
