import { Icon as LegacyIcon } from '@ant-design/compatible'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Popover, Tooltip } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { colorRed20, colorCerulean } from '@signifyd/colors'
import {
  ExternalLink,
  T4,
  Text,
  i18nInstance,
  DevToolsIcon,
} from '@signifyd/components'
import {
  CHECKPOINT_ACTION,
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  IntegrationAnalysisOrder,
} from '@signifyd/http'
import useDevToolsFeatureFlags from 'core/components/UserLoader/useDevToolsFeatureFlags'
import {
  dataQualityPanelMissingFieldsToOmit,
  ORDERS_APP_URL,
} from 'core/constants'
import YesNoCell from 'pages/DataQualityPage/components/SearchResultsTable/YesNoCell'
import { ColumnProps } from 'antd/lib/table'
import styles from './Columns.less'
import CurrencyCell from '../CurrencyCell/CurrencyCell'
import DateTimeCell from '../DateTimeCell/DateTimeCell'
import SearchTableHeader from '../SearchTableHeader/SearchTableHeader'
import TableTag from '../TableTag/TableTag'

export type TableColumn = ColumnProps<IntegrationAnalysisOrder>

interface ColumnConfigProps {
  locationOrigin: string
  setExpandedRowKey: (rowNumber: string | undefined) => void
  expandedRowKey?: string
}

const { t } = i18nInstance

const AllFieldsPresent: FC = () => {
  return (
    <TableTag
      type="primary"
      color="rgba(0, 138, 0, 0.25)"
      data-test-id="expandFieldQuality"
    >
      {t('dataQualityPage.table.fieldQualityColumn.values.allFields')}
    </TableTag>
  )
}

// TODO FET-1852 https://signifyd.atlassian.net/browse/FET-1853
// Use antd props so that you get key values automatically
const useGetColumns = ({
  locationOrigin,
  setExpandedRowKey,
  expandedRowKey,
}: ColumnConfigProps): Array<TableColumn> => {
  const { isNewDevToolsEnabled } = useDevToolsFeatureFlags()

  const columns: Array<TableColumn> = [
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.orderIdColumn.title')}
          columnKey="orderId"
        />
      ),
      dataIndex: 'orderId',
      key: 'orderId',
      width: 300,
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.caseIdColumn.title')}
          columnKey="signifydId"
        />
      ),
      dataIndex: 'signifydId',
      key: 'signifydId',
      render: (text: string) => {
        return (
          <Tooltip
            title={t('dataQualityPage.table.orderIdColumn.tooltip', {
              caseId: text,
            })}
          >
            <ExternalLink url={`${ORDERS_APP_URL}/${text}`}>
              {text}
            </ExternalLink>
          </Tooltip>
        )
      },
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.orderDateColumn.title')}
          columnKey="normalizedPurchaseCreatedAt"
        />
      ),
      key: 'orderDate',
      render: (_: string, rowData: IntegrationAnalysisOrder) => (
        <DateTimeCell rowData={rowData} />
      ),
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.emailColumn.title')}
          columnKey="confirmationEmail"
        />
      ),
      dataIndex: 'confirmationEmail',
      key: 'email',
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.amountColumn.title')}
          columnKey="orderTotalAmount"
        />
      ),
      dataIndex: 'orderTotalAmount',
      key: 'amount',
      render: (
        amount: number | undefined | null,
        { currency = 'USD' }: IntegrationAnalysisOrder
      ) => <CurrencyCell amount={amount} currency={currency} />,
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.paymentMethodColumn.title')}
          columnKey="paymentMethod"
        />
      ),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.orderChannelColumn.title')}
          columnKey="orderChannel"
        />
      ),
      dataIndex: 'orderChannel',
      key: 'orderChannel',
      render: (orderChannel: INTEGRATION_ANALYSIS_ORDER_CHANNEL) => {
        return t(
          `dataQualityPage.table.orderChannelColumn.values.${orderChannel}`
        )
      },
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.checkpointActionColumn.title')}
          columnKey="checkpointAction"
        />
      ),
      dataIndex: 'checkpointAction',
      key: 'checkpointAction',
      render: (checkpointAction?: CHECKPOINT_ACTION) => {
        if (!checkpointAction) {
          return null
        }

        return t(
          `dataQualityPage.table.checkpointActionColumn.values.${checkpointAction}`
        )
      },
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.orderSessionIdColumn.title')}
          columnKey="orderSessionId"
        />
      ),
      dataIndex: 'orderSessionId',
      key: 'orderSessionId',
      render: (orderSessionId: boolean) => {
        return <Text>{orderSessionId}</Text>
      },
      width: 300,
    },
    {
      title: (
        <SearchTableHeader
          title={t(
            'dataQualityPage.table.deviceFingerprintingRequestedColumn.title'
          )}
          columnKey="deviceFingerprintingRequested"
        />
      ),
      dataIndex: 'deviceFingerprintingRequested',
      key: 'deviceFingerprintingRequested',
      onCell: (record) => {
        return {
          className: cx({
            [styles.fullBgCell]: true,
            [styles.removeBottomBorder]: !record.deviceFingerprintingRequested,
          }),
        }
      },
      render: (deviceFingerprintingRequested: boolean) => {
        return <YesNoCell showYes={deviceFingerprintingRequested} />
      },
      width: 200,
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.deviceIdColumn.title')}
          columnKey="deviceId"
        />
      ),
      dataIndex: 'deviceId',
      key: 'deviceId',
      onCell: (record) => {
        return {
          className: cx({
            [styles.fullBgCell]: true,
            [styles.removeBottomBorder]: !record.deviceId,
          }),
        }
      },
      render: (deviceId: boolean) => {
        const translationKey =
          'dataQualityPage.table.deviceFingerprintingColumn'

        const tooltip = (
          <Popover
            title={
              <div className={styles.popoverHeader}>
                <T4>{t(`${translationKey}.popoverTitle`)}</T4>
                <Text>{t(`${translationKey}.popoverSubtitle`)}</Text>
              </div>
            }
            content={
              <ExternalLink
                target="_blank"
                url="https://developer.signifyd.com/ecommerce-integration-guide/fingerprinting/#steps-to-solve"
              >
                {t(`${translationKey}.values.linkText`)}
              </ExternalLink>
            }
          >
            <ExclamationCircleOutlined />
          </Popover>
        )

        return <YesNoCell showYes={!!deviceId} tooltip={tooltip} />
      },
    },
    {
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.fieldQualityColumn.title')}
          columnKey="incomingEvents"
        />
      ),
      dataIndex: 'incomingEvents',
      key: 'incomingEvents',
      onCell: ({ incomingEvents, signifydId }) => ({
        onClick: () => {
          if (!incomingEvents.length) {
            return
          }

          const firstEvent = incomingEvents[0]
          const missingFields = firstEvent.fieldAnalyses.filter(
            (fieldAnalysis) =>
              !dataQualityPanelMissingFieldsToOmit.includes(
                fieldAnalysis.issuePath
              )
          )

          if (missingFields.length > 0) {
            const rowKey = `${signifydId}`
            const isRowOpen = rowKey === expandedRowKey
            setExpandedRowKey(isRowOpen ? undefined : rowKey)
          }
        },
      }),
      render: (
        incomingEvents: IntegrationAnalysisOrder['incomingEvents'],
        { signifydId }
      ) => {
        if (!incomingEvents.length) {
          return <AllFieldsPresent />
        }

        const [firstEvent] = incomingEvents
        const missingFields = firstEvent.fieldAnalyses.filter(
          (fieldAnalysis) =>
            !dataQualityPanelMissingFieldsToOmit.includes(
              fieldAnalysis.issuePath
            )
        )

        if (!missingFields.length) {
          return <AllFieldsPresent />
        }

        const rowKey = `${signifydId}`
        const isRowOpen = rowKey === expandedRowKey

        return (
          <>
            <TableTag
              type="primary"
              color={colorRed20}
              data-test-id="expandFieldQuality"
              disableAnimation
            >
              <span>
                {t(
                  'dataQualityPage.table.fieldQualityColumn.values.missingFieldsTag',
                  { count: missingFields.length }
                )}
              </span>
              <LegacyIcon
                type={isRowOpen ? 'up' : 'down'}
                className={styles.toggleRowIcon}
              />
            </TableTag>
          </>
        )
      },
    },
  ]

  if (isNewDevToolsEnabled) {
    columns.push({
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.jsonColumn.title')}
          columnKey="json"
        />
      ),
      dataIndex: 'orderId',
      key: 'json',
      render: (orderId: string, { teamId = '5921902' }) => {
        return (
          <Tooltip overlay={t('dataQualityPage.table.jsonColumn.tooltip')}>
            <ExternalLink
              data-test-id={`jsonLink${orderId}`}
              className={styles.jsonLink}
              target="_blank"
              url={`${locationOrigin}/json/team/${teamId}/order/${orderId}`}
            >
              <DevToolsIcon fill={colorCerulean} />
            </ExternalLink>
          </Tooltip>
        )
      },
    })
  } else {
    columns.push({
      title: (
        <SearchTableHeader
          title={t('dataQualityPage.table.jsonColumn.title')}
          columnKey="json"
        />
      ),
      dataIndex: 'signifydId',
      key: 'json',
      render: (signifydId: string) => {
        return (
          <Tooltip overlay={t('dataQualityPage.table.jsonColumn.tooltip')}>
            <ExternalLink
              data-test-id={`jsonLink${signifydId}`}
              className={styles.jsonLink}
              target="_blank"
              url={`${locationOrigin}/json/${signifydId}`}
            >
              <DevToolsIcon fill={colorCerulean} />
            </ExternalLink>
          </Tooltip>
        )
      },
    })
  }

  return columns
}

export default useGetColumns
