import { message } from 'antd'
import { FC, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { CopyIcon } from '@signifyd/components'
import styles from './CopyButton.less'

const displaySuccessMessage = (messageText: string): void => {
  message.success(messageText, 2)
}

interface Props {
  text?: string
}

const CopyButton: FC<Props> = ({ text }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)

  if (!text) {
    return null
  }

  return (
    <div className={styles.copyButton}>
      <CopyToClipboard
        data-test-id="copy-button"
        text={text}
        onCopy={() => {
          setIsCopied(true)
          displaySuccessMessage(t('jsonViewerPage.copyButton.toast'))
          setTimeout(() => {
            setIsCopied(false)
          }, 2000)
        }}
      >
        <button type="button" className={styles.button}>
          <CopyIcon fill="white" />
          {isCopied
            ? t('jsonViewerPage.copyButton.copied')
            : t('jsonViewerPage.copyButton.copy')}
        </button>
      </CopyToClipboard>
    </div>
  )
}

export default CopyButton
