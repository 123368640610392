import { message } from 'antd'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'
import { i18nInstance } from '@signifyd/components'
import { StoreDataUpdate, StoreData, UserTeam } from '@signifyd/http'
import useCreateUserTeam from 'core/queries/useCreateUserTeam'
import useUpdateUserTeam from 'core/queries/useUpdateUserTeam'
import useUpdateUserTeamStore from 'core/queries/useUpdateUserTeamStore'
import { FormikSubmitFunction } from '../Form'
import { TeamFormValues } from './TeamForm.types'

const { t } = i18nInstance

const TeamFormSchema = yup.object().shape({
  storeName: yup.string().required('Store name is required'),
  storeUrl: yup.string(),
})

interface Args {
  onSuccess?: (teamId: number | null) => void
  editMode?: boolean
  teamId?: number
  onlineStore?: StoreData
}

/**
 * Provides the formik context to hook up to the TeamForm and other components needing the form data (submit buttons, for instance)
 */
const useTeamFormik = ({
  onSuccess,
  editMode = false,
  teamId,
  onlineStore,
}: Args = {}): FormikProps<TeamFormValues> => {
  const updateUserTeam = useUpdateUserTeam()
  const createUserTeam = useCreateUserTeam()
  const updateTeamStore = useUpdateUserTeamStore()
  const messageDuration = 5

  const updateUserTeamStore = async (
    updatedData: UserTeam,
    formValues: TeamFormValues
  ): Promise<void> => {
    const { onlineStoreId, teamId: createTeamId }: UserTeam = updatedData
    const id = onlineStore?.id || onlineStoreId

    if (id) {
      const {
        storeName,
        storeUrl,
        programmingLanguage,
        selectedPaymentGateway,
        mobileAppPlatform,
      } = formValues

      const storeUpdateBody: StoreDataUpdate = {
        id,
        storeName,
        storeUrl,
        programmingLanguage,
        paymentGateways: selectedPaymentGateway ? [selectedPaymentGateway] : [],
        mobileAppPlatform,
        version: onlineStore?.version ?? 1,
      }

      await updateTeamStore.mutateAsync({
        storeData: storeUpdateBody,
        teamName: storeName,
      })

      if (editMode) {
        message.success(
          t('settingsPage.editTeamContainer.successMessage', { storeName }),
          messageDuration
        )
      }

      onSuccess?.(createTeamId)
    }
  }

  const onSubmit: FormikSubmitFunction<TeamFormValues> = async (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true)

    const updatedDate = await (editMode
      ? updateUserTeam.mutateAsync({
          teamName: values.storeName,
          teamId: Number(teamId),
        })
      : createUserTeam.mutateAsync({
          teamName: values.storeName,
        }))

    await updateUserTeamStore(updatedDate, values)

    setSubmitting(false)
  }

  const store = onlineStore ?? {}

  const initialFormValues: TeamFormValues = {
    storeName: '',
    storeUrl: '',
    selectedPaymentGateway: onlineStore?.paymentGateways?.[0],
    ...store,
  }

  const teamFormik = useFormik({
    initialValues: initialFormValues,
    onSubmit,
    validationSchema: TeamFormSchema,
    validateOnMount: true,
    enableReinitialize: true,
  })

  return teamFormik
}

export default useTeamFormik
