import { EnumLike } from 'zod'
import { i18nInstance } from '@signifyd/components'
import {
  CHECKPOINT_ACTIONS,
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  SEARCH_FIELD,
  SEARCH_OPERATOR,
} from '@signifyd/http'
import {
  DecodedSearchParams,
  isRangeFilter,
  SearchKeys,
  SearchParamsMap,
} from '@signifyd/utils'

const { t } = i18nInstance

export interface StringRangeFilter {
  min: string
  max: string
}

export type FilterStateValues = string | StringRangeFilter

export enum COL_FILTER_TYPES {
  BOOLEAN = 1,
  TEXT = 2,
  NUMBER_WITH_DELIMITER = 3,
  NUMBER_WITH_RANGE = 4,
  MULTI_SELECT = 5,
}

export type FilterValue<T extends COL_FILTER_TYPES> =
  T extends COL_FILTER_TYPES.NUMBER_WITH_RANGE ? StringRangeFilter : string

// Most columns are 1 to 1 with query keys, where filtering on the key will automatically update the query string with the changed value
interface StandardColumnFilter {
  TYPE?: COL_FILTER_TYPES
  canOrderBy?: boolean
  searchKey: SearchKeys
}

// Custom columns get & pass values to the query string via functions, which allows them to be composite
// Custom columns do not support ordering by at this time
export interface CustomColumnFilter {
  TYPE: COL_FILTER_TYPES
  searchKey: 'custom'
  getValuesFromQuery: (query: DecodedSearchParams) => string
  encodeValueForQuery: (
    filterValue: string
  ) => Partial<Record<SearchKeys, unknown>>
  multiselect?: {
    options: () => Array<{ value: string; label: string }>
  }
}

export type ColumnFilterData = StandardColumnFilter | CustomColumnFilter

const encodeEnumValues = <T extends EnumLike>(
  filterValue: string,
  searchField: T
): Array<string> | null => {
  if (!filterValue.length) {
    return null
  }

  return Object.values(searchField)
    .map(String)
    .filter((value) => filterValue.split(',').includes(value))
}

export const getFilterStateValue = (
  columnData: ColumnFilterData,
  query: DecodedSearchParams
): FilterStateValues => {
  const filterValue =
    columnData.searchKey === 'custom'
      ? columnData.getValuesFromQuery(query)
      : query[columnData.searchKey]

  if (isRangeFilter(filterValue)) {
    const { min, max } = filterValue

    return { min: min?.toString() ?? '', max: max?.toString() ?? '' }
  }

  return filterValue?.toString() ?? ''
}

export const getFilterValueFromArray = (
  query: DecodedSearchParams,
  field: keyof SearchParamsMap
): string => {
  const value = query[field]

  if (!value || !Array.isArray(value)) {
    return typeof value === 'string' ? value : ''
  }

  return value.join(',')
}

export const getQueryFromFilterState = (
  value: FilterStateValues,
  columnData: ColumnFilterData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Partial<Record<SearchKeys, any>> => {
  if (columnData.searchKey === 'custom') {
    return columnData.encodeValueForQuery(value as string)
  }

  return {
    [columnData.searchKey]: value || null,
  }
}

export const COL_FILTERS: Record<string, ColumnFilterData> = {
  normalizedPurchaseCreatedAt: {
    TYPE: undefined,
    canOrderBy: true,
    searchKey: SEARCH_FIELD.normalizedPurchaseCreatedAt,
  },
  paymentMethod: {
    TYPE: COL_FILTER_TYPES.TEXT,
    canOrderBy: false,
    searchKey: SEARCH_FIELD.paymentMethod,
  },
  orderSessionId: {
    TYPE: COL_FILTER_TYPES.TEXT,
    canOrderBy: false,
    searchKey: SEARCH_FIELD.orderSessionId,
  },
  deviceFingerprintingRequested: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(
        query,
        SEARCH_FIELD.deviceFingerprintingRequested
      ),

    encodeValueForQuery: (filterValue) => {
      return {
        [SEARCH_FIELD.deviceFingerprintingRequested]: filterValue
          ? [filterValue]
          : undefined,
      }
    },
    multiselect: {
      options: () => {
        return [
          {
            value: 'true',
            label: t(
              `columnFilters.inputs.multiselect.deviceFingerprintingRequested.true`
            ),
          },
          {
            value: 'false',
            label: t(
              `columnFilters.inputs.multiselect.deviceFingerprintingRequested.false`
            ),
          },
        ]
      },
    },
  },
  deviceId: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.deviceId),

    encodeValueForQuery: (filterValue) => {
      const values = filterValue.split(',')
      if (
        values.includes(SEARCH_OPERATOR.NOT_NULL) &&
        values.includes(SEARCH_OPERATOR.NULL)
      ) {
        return {
          [SEARCH_FIELD.deviceId]: null,
        }
      }

      return {
        [SEARCH_FIELD.deviceId]: filterValue || null,
      }
    },
    multiselect: {
      options: () => {
        return [
          {
            value: SEARCH_OPERATOR.NOT_NULL,
            label: t(
              `columnFilters.inputs.multiselect.deviceFingerprintingRequested.true`
            ),
          },
          {
            value: SEARCH_OPERATOR.NULL,
            label: t(
              `columnFilters.inputs.multiselect.deviceFingerprintingRequested.false`
            ),
          },
        ]
      },
    },
  },
  orderChannel: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.orderChannel),

    encodeValueForQuery: (filterValue) => {
      const orderChannels = encodeEnumValues(
        filterValue,
        INTEGRATION_ANALYSIS_ORDER_CHANNEL
      )

      return {
        [SEARCH_FIELD.orderChannel]: orderChannels,
      }
    },
    multiselect: {
      options: () =>
        Object.values(INTEGRATION_ANALYSIS_ORDER_CHANNEL).map(
          (value: INTEGRATION_ANALYSIS_ORDER_CHANNEL) => ({
            value,
            label: t(`columnFilters.inputs.multiselect.orderChannel.${value}`),
          })
        ),
    },
  },
  checkpointAction: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.checkpointAction),

    encodeValueForQuery: (filterValue) => {
      const checkpointActions = encodeEnumValues(
        filterValue,
        CHECKPOINT_ACTIONS
      )

      return {
        [SEARCH_FIELD.checkpointAction]: checkpointActions,
      }
    },
    multiselect: {
      options: () =>
        Object.values(CHECKPOINT_ACTIONS).map((value) => ({
          value,
          label: t(
            `columnFilters.inputs.multiselect.checkpointAction.${value}`
          ),
        })),
    },
  },
}

export type ColFilterKey = keyof typeof COL_FILTERS
export type ColFilters = Partial<{
  [key in ColFilterKey]: string
}>
