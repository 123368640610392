import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { FC, useState, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  T2,
  Space,
  StoreIcon,
  useIsThirdGen,
  PageSpinnerThirdGen,
} from '@signifyd/components'
import HeaderBar from 'core/components/HeaderBar/HeaderBar'
import LoadingSpinner from 'core/components/LoadingSpinner'
import PagePlaceholder from 'core/components/PagePlaceholder'
import PageWrapper from 'core/components/PageWrapper'
import useGetEnhancedUserTeams from 'core/queries/useGetEnhancedUserTeams'
import useGetUserTeams from 'core/queries/useGetUserTeams'
import useGetSelectedTeams from 'core/utils/useGetSelectedTeams'
import { TeamFilter } from 'pages/DataQualityPage/components'
import IntegrationResourcesModal from './components/IntegrationResourcesModal'
import TeamCard from './components/TeamCard'
import TeamCreationSuccessModal from './components/TeamCreationSuccessModal'
import styles from './TeamsPage.less'

const TeamsPage: FC = () => {
  const { t } = useTranslation()
  const { selectedTeams } = useGetSelectedTeams()
  const [teamCreatedId, setTeamCreatedId] = useState<number | null>(null)
  const isThirdGen = useIsThirdGen()

  const [integrationGuideTeamId, setIntegrationGuideTeamId] = useState<
    number | null
  >(null)

  const { data: userTeams, isLoading: userTeamsLoading } = useGetUserTeams()
  const {
    data: enhancedUserTeams,
    isLoading: enhancedUserTeamsLoading,
    isFetching: enhancedUserTeamsFetching,
  } = useGetEnhancedUserTeams(selectedTeams)

  const integrationGuideTeam = useMemo(() => {
    if (!integrationGuideTeamId) {
      return undefined
    }

    return enhancedUserTeams?.find(
      ({ teamId }) => teamId === integrationGuideTeamId
    )
  }, [enhancedUserTeams, integrationGuideTeamId])

  const hasUserTeams = !!userTeams?.length && !userTeamsLoading

  const loading =
    userTeamsLoading ||
    (hasUserTeams && enhancedUserTeamsLoading) ||
    enhancedUserTeamsFetching

  const userTeamIds = userTeams?.map((team) => team.teamId.toString()) ?? []
  const selectedTeamsQueryString = selectedTeams.join('_')

  const validTeams = selectedTeams?.filter((id) => userTeamIds.includes(id))

  if (!isThirdGen) {
    return (
      <PageWrapper>
        <section className={styles.teamsPage} data-test-id="teams-page">
          <header className={styles.header} data-test-id="teamsHeader">
            <T2 className={styles.title}>{t('teamsPage.title')}</T2>

            {!loading && (
              <div>
                {!!validTeams && <TeamFilter validTeams={validTeams} />}
                <Button
                  className={styles.createBtn}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  <Link to={`create?teamId=${selectedTeamsQueryString}`}>
                    {t('teamsPage.createTestTeamButton')}
                  </Link>
                </Button>
              </div>
            )}
          </header>
          <Space size="md" />
          {loading && <LoadingSpinner />}
          {!loading && !enhancedUserTeams?.length && (
            <PagePlaceholder
              title={<Trans i18nKey="teamsPage.noResults.title" />}
              description={<Trans i18nKey="teamsPage.noResults.description" />}
              icon={StoreIcon}
            />
          )}
          <Row gutter={[32, 32]}>
            {!loading &&
              enhancedUserTeams?.map((team) => (
                <Col key={team.teamId} md={12} xl={8} xxl={6}>
                  <TeamCard
                    viewResources={() => setIntegrationGuideTeamId(team.teamId)}
                    team={team}
                    selectedTeams={selectedTeamsQueryString}
                  />
                </Col>
              ))}
          </Row>
          {integrationGuideTeam?.onlineStore && (
            <IntegrationResourcesModal
              dismissModal={() => setIntegrationGuideTeamId(null)}
              onlineStore={integrationGuideTeam.onlineStore}
            />
          )}
          {teamCreatedId && (
            <TeamCreationSuccessModal
              dismissModal={() => setTeamCreatedId(null)}
              viewResources={() => setIntegrationGuideTeamId(teamCreatedId)}
            />
          )}
        </section>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <section data-test-id="teams-page">
        <HeaderBar title={t('teamsPage.title')}>
          {!loading && (
            <div>
              {!!validTeams && <TeamFilter validTeams={validTeams} />}
              <Button
                className={styles.createBtn}
                type="primary"
                icon={<PlusOutlined />}
              >
                <Link to={`create?teamId=${selectedTeamsQueryString}`}>
                  {t('teamsPage.createTestTeamButton')}
                </Link>
              </Button>
            </div>
          )}
        </HeaderBar>
        {loading && <PageSpinnerThirdGen />}
        {!loading && !enhancedUserTeams?.length && (
          <PagePlaceholder
            title={<Trans i18nKey="teamsPage.noResults.title" />}
            description={<Trans i18nKey="teamsPage.noResults.description" />}
            icon={StoreIcon}
            marginBottom={0}
          />
        )}
        <div className={styles.container}>
          <Row gutter={[32, 32]}>
            {!loading &&
              enhancedUserTeams?.map((team) => (
                <Col key={team.teamId} md={12} xl={8} xxl={6}>
                  <TeamCard
                    viewResources={() => setIntegrationGuideTeamId(team.teamId)}
                    team={team}
                    selectedTeams={selectedTeamsQueryString}
                  />
                </Col>
              ))}
          </Row>
        </div>
        {integrationGuideTeam?.onlineStore && (
          <IntegrationResourcesModal
            dismissModal={() => setIntegrationGuideTeamId(null)}
            onlineStore={integrationGuideTeam.onlineStore}
          />
        )}
        {teamCreatedId && (
          <TeamCreationSuccessModal
            dismissModal={() => setTeamCreatedId(null)}
            viewResources={() => setIntegrationGuideTeamId(teamCreatedId)}
          />
        )}
      </section>
    </PageWrapper>
  )
}

export default TeamsPage
