import { FC } from 'react'
import {
  FeatureFlagConfig,
  FeatureFlagController,
  PageSpinnerThirdGen,
  TestFeatureFlag,
  thirdGenTreatmentKey,
} from '@signifyd/components'
import { User } from '@signifyd/http'

interface Props {
  user: User
  testFeatureFlags?: TestFeatureFlag
}

export const featureFlagConfig: Array<FeatureFlagConfig> = [
  {
    key: thirdGenTreatmentKey,
    label: 'Third Gen',
  },
  {
    key: 'has-new-devtools',
    label: 'New DevTools',
  },
] as const

const DevToolsFeatureFlagController: FC<Props> = ({
  user,
  testFeatureFlags,
  children,
}) => {
  return (
    <FeatureFlagController
      user={user}
      featureFlagConfig={featureFlagConfig}
      testFeatureFlags={testFeatureFlags}
      LoadingComponent={<PageSpinnerThirdGen />}
    >
      {children}
    </FeatureFlagController>
  )
}

export default DevToolsFeatureFlagController
