import { DeleteOutlined } from '@ant-design/icons'
import { Popover, Row, Button } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { P, T4 } from '@signifyd/components'
import { AllWebhookData } from '@signifyd/http'
import { useDeleteWebhook } from 'core/queries/useDeleteWebhook'
import styles from './DeleteWebhookPopover.less'

interface Props {
  teamId: number
  webhook: AllWebhookData
}

const DeleteWebhookPopover: React.FC<Props> = ({ teamId, webhook }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  const { t } = useTranslation()

  const { mutate: deleteWebhook, isLoading: isWebhookDeleting } =
    useDeleteWebhook(teamId)

  const handleDeleteWebhook = async (): Promise<void> => {
    deleteWebhook(webhook)
  }

  return (
    <Popover
      destroyTooltipOnHide
      placement="left"
      overlayClassName={styles.popover}
      data-test-id="deleteWebhookPopover"
      title={
        <>
          <T4>{t('settingsPage.webhooksContainer.popover.title')}</T4>
          <P>{t('settingsPage.webhooksContainer.popover.description')}</P>
        </>
      }
      content={
        <Row data-test-id="deleteWebhookContent" justify="space-between">
          <Button
            data-test-id="deleteWebhookCancelButton"
            className={styles.popoverButton}
            type="link"
            disabled={isWebhookDeleting}
            onClick={() => setIsPopoverVisible(false)}
          >
            {t('settingsPage.webhooksContainer.popover.cancelButton')}
          </Button>
          <Button
            data-test-id="deleteWebhookButton"
            className={styles.popoverButton}
            type="link"
            disabled={isWebhookDeleting}
            onClick={handleDeleteWebhook}
          >
            {t('settingsPage.webhooksContainer.popover.deleteButton')}
          </Button>
        </Row>
      }
      open={isPopoverVisible}
    >
      <DeleteOutlined
        data-test-id="deleteWebhookIcon"
        className={styles.deleteIcon}
        onClick={() => setIsPopoverVisible(true)}
      />
    </Popover>
  )
}

export default DeleteWebhookPopover
