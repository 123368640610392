import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { message } from 'antd'
import { useStoreState } from 'stores'
import { UserTeam, getTeam, listUserTeams } from '@signifyd/http'
import useGetSelectedTeams from 'core/utils/useGetSelectedTeams'

// This is also constrained by the team select, but let's add this just as a fallback
const MAX_TEAM_SELECTION = 50

const useGetUserTeams = (): UseQueryResult<Array<UserTeam>> => {
  const currentUser = useStoreState((state) => state.user.currentUser)
  const currentUserId = currentUser?.userId

  const { selectedTeams: teamIds } = useGetSelectedTeams()

  return useQuery(
    ['userTeams', teamIds],
    async () => {
      if (!currentUser?.isAdmin) {
        const { data } = await listUserTeams(currentUserId!)

        return data.teams.filter((team) => team.active)
      }

      if (teamIds?.length > MAX_TEAM_SELECTION) {
        message.error(
          'You have selected too many teams; please reduce your selections to view data.'
        )

        return []
      }
      const responses = await Promise.all(
        teamIds.map((teamId) => getTeam(+teamId))
      )

      return responses.map((response) => response.data)
    },
    {
      enabled: !!currentUserId,
    }
  )
}

export default useGetUserTeams
