import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {
  ApiAnalysisResponse,
  ApiAnalysisRequest,
  // getApiAnalysis,
} from '@signifyd/http'

export const API_ANALYSIS_QUERY_KEY = 'apiAnalysis'

interface UseGetApiAnalysisOptions {
  options?: Omit<
    UseQueryOptions<ApiAnalysisResponse, AxiosError>,
    'queryKey' | 'queryFn'
  >
}

// TODO: Remove once HTTP calls implemented
const getMockedApiAnalysis = async ({
  teamId: _,
  orderId,
  apiName,
  apiCallNumber: __,
}: ApiAnalysisRequest): Promise<ApiAnalysisResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        signifydId: 929574632,
        orderId,
        apiAnalysis: {
          eventType: 'CheckoutEvent',
          apiName,
          apiLabel: 'Attempt Return',
          lastTimestamp: '2020-12-23T13:57:40-0700',
          apiAnalysisPresent: true,
          fieldAnalyses: [
            {
              issuePath: 'purchase.browserIpAddress',
              fieldDescription:
                'The IP Address of the browser that was used to make the purchase. This is the IP Address that was used to connect to your site and make the purchase.',
            },
          ],
        },
      })
    }, 1000)
  })
}

export const useGetApiAnalysis = (
  { teamId, orderId, apiName, apiCallNumber }: Partial<ApiAnalysisRequest>,
  options?: UseGetApiAnalysisOptions
): UseQueryResult<ApiAnalysisResponse, AxiosError> => {
  const { t } = useTranslation()

  return useQuery<ApiAnalysisResponse, AxiosError>({
    queryKey: [API_ANALYSIS_QUERY_KEY, teamId, orderId, apiName, apiCallNumber],
    queryFn: () =>
      getMockedApiAnalysis({
        teamId: teamId!,
        orderId: orderId!,
        apiName: apiName!,
        apiCallNumber,
      }),
    onError: async (e) => {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        await message.error(t('jsonViewerPage.notFound'))
      } else {
        await message.error(t('jsonViewerPage.somethingWrong'))
      }
    },
    ...options,
    enabled: !!orderId && !!teamId && !!apiName,
  })
}
