import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import { Flex } from 'antd'
import { FC, ReactNode } from 'react'
import { Text } from '@signifyd/components'
import styles from './SideBarGroup.less'

export interface SideBarGroupProps {
  isExpanded: boolean
  label: string
  children: ReactNode
  onClick: () => void
}

const SideBarGroup: FC<SideBarGroupProps> = ({
  isExpanded,
  label,
  children,
  onClick,
}) => {
  return (
    <Flex vertical>
      <Flex
        align="center"
        justify="space-between"
        className={styles.header}
        component="button"
        onClick={onClick}
      >
        <Text type={'secondary'}>{label}</Text>

        {isExpanded && <MinusCircleFilled className={styles.groupIcon} />}
        {!isExpanded && <PlusCircleFilled className={styles.groupIcon} />}
      </Flex>
      {isExpanded && children}
    </Flex>
  )
}

export default SideBarGroup
