import { LoadingOutlined } from '@ant-design/icons'
import { UseQueryResult } from '@tanstack/react-query'
import { Table } from 'antd'
import { FC, useState } from 'react'
import { useIsThirdGen } from '@signifyd/components'
import { INTEGRATION_ANALYSIS_ORDER_CHANNEL } from '@signifyd/http'
import { SearchResults } from 'pages/DataQualityPage/utils/flexibleSearch'
import useGetColumns from './columns/ColumnConfig'
import MissingFieldsContent from './MissingFieldsContent/MissingFieldsContent'
import NoSearchResults from './NoSearchResults/NoSearchResults'
import styles from './SearchResultsTable.less'

interface Props {
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
  rangeType?: 'rangeA' | 'rangeB' | undefined
  dataFetcher: {
    (): UseQueryResult<SearchResults, unknown>
    (
      rangeType?: 'rangeA' | 'rangeB',
      orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
    ): UseQueryResult<SearchResults, unknown>
  }
}

const SearchResultsTable: FC<Props> = ({
  dataFetcher,
  rangeType,
  orderChannel,
}) => {
  const [expandedRowKey, setExpandedRowKey] = useState<string>()
  const isThirdGen = useIsThirdGen()

  const defaultData: Partial<SearchResults> = {
    results: [] as SearchResults['results'],
  }

  const {
    data = defaultData,
    isFetching,
    remove,
    refetch,
  } = dataFetcher(rangeType, orderChannel)

  const columns = useGetColumns({
    expandedRowKey,
    setExpandedRowKey,
    locationOrigin: window.location.origin,
  })

  const investigations = data.results || []

  return (
    <div data-test-id="searchResultsTable" className={styles.resultsTable}>
      <Table
        pagination={false}
        columns={columns}
        rowKey={(record) => `${record.signifydId}`}
        dataSource={investigations}
        scroll={{ x: 'max-content' }}
        size={isThirdGen ? undefined : 'middle'}
        loading={{
          spinning: isFetching,
          indicator: <LoadingOutlined data-test-id="searchTableSpinner" />,
        }}
        expandable={{
          expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
          expandIcon: () => <></>,
          expandedRowRender: ({ incomingEvents, signifydId }) => {
            return (
              <>
                {incomingEvents.length && (
                  <MissingFieldsContent
                    incomingEvent={incomingEvents[0]}
                    signifydId={signifydId.toString()}
                  />
                )}
              </>
            )
          },
        }}
        locale={{
          emptyText: (
            <NoSearchResults
              investigations={investigations}
              remove={remove}
              isFetching={isFetching}
              refetch={refetch}
            />
          ),
        }}
      />
    </div>
  )
}

export default SearchResultsTable
