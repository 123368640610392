import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import {
  WebhookData,
  deleteWebhook,
  deleteV3Webhook,
  WEBHOOK_REQUEST_VERSIONS,
  AllWebhookData,
} from '@signifyd/http'
import getSigErrorMessage from 'core/utils/getSigErrorMessage'

export const useDeleteWebhook = (
  teamId: number
): UseMutationResult<
  WebhookData | AxiosResponse,
  AxiosError,
  AllWebhookData
> => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  return useMutation<WebhookData | AxiosResponse, AxiosError, AllWebhookData>({
    mutationFn: async ({ apiVersion, id, teamId }: AllWebhookData) => {
      if (apiVersion === WEBHOOK_REQUEST_VERSIONS.V3) {
        return deleteV3Webhook(teamId, id)
      }

      const { data } = await deleteWebhook(Number(id))

      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['webhooks', teamId] })

      const messageDuration = 2

      message.success(
        t('settingsPage.webhooksContainer.successMessages.deleteWebhook'),
        messageDuration
      )
    },
    onError: (err) => {
      const error =
        getSigErrorMessage(err) ??
        t('settingsPage.webhooksContainer.fallbackErrorMessage.deleteWebhook')

      message.error(error)
    },
  })
}
