import moment from 'moment-timezone'
import { API_CONTENT_TYPE, WebhookSummary } from '@signifyd/http'
import type { TabKeys } from 'pages/NewJSONViewerPage/types'

export type WebhookEvent = WebhookSummary & {
  webhookTopicLabel: string
  destinationUrlIndex: number
}

export enum API_TYPE {
  API = 'api',
  WEBHOOK = 'webhook',
}

export const isV3Webhook = (webhook: WebhookEvent): boolean => {
  return webhook.lastWebhook.checkpoint != null
}

export const getContentType = (
  isApiEvent: boolean,
  tab: TabKeys
): API_CONTENT_TYPE => {
  if (isApiEvent) {
    return tab === 'first'
      ? API_CONTENT_TYPE.SAVED_EVENT
      : API_CONTENT_TYPE.REQUEST
  }

  return tab === 'first' ? API_CONTENT_TYPE.REQUEST : API_CONTENT_TYPE.RESPONSE
}

export const getDocumentationUrl = (
  eventType: API_TYPE,
  apiName?: string | null
): string => {
  if (eventType === 'webhook') {
    return 'https://developer.signifyd.com/main/reference/webhookmanagement'
  }

  if (apiName) {
    return `https://developer.signifyd.com/main/reference/${apiName.replace('_', '')}`
  }

  return ''
}

export const getFormattedDate = (timestamp: string): string =>
  moment.utc(timestamp).format('MM/DD/YY HH:mm:ss z')
