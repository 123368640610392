import { Tooltip } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colorJade, colorRed } from '@signifyd/colors'
import { QuestionCircleIcon, SigTag, Space, Text } from '@signifyd/components'
import { IntegrationAnalysisFieldAnalyses } from '@signifyd/http'
import styles from './ApiDataQuality.less'

interface Props {
  fieldAnalysis: Array<IntegrationAnalysisFieldAnalyses>
}

const ApiDataQuality: FC<Props> = ({ fieldAnalysis }) => {
  const { t } = useTranslation()

  const fieldStatus = fieldAnalysis.length
    ? 'missingFields'
    : 'allFieldsPresent'

  return (
    <div className={styles.panel} data-test-id="dataQualityPanel">
      <Text block className={styles.textColor} size="lg" weight="semibold">
        {t('jsonViewerPage.dataQuality.fieldQualityTitle')}
      </Text>
      <Space size="md" />
      <div
        className={styles.missingFields}
        data-test-id="data-quality-missing-fields"
      >
        <Text
          block
          className={styles.textColor}
          size="sm"
          data-test-id={`dataQualityPanelMessage-${fieldStatus}`}
        >
          {t(`jsonViewerPage.dataQuality.${fieldStatus}.action`)}
        </Text>
        <Space size="sm" />
        <SigTag
          color={fieldAnalysis.length ? colorRed : colorJade}
          type="primary"
          className={styles.tag}
        >
          <Text size="sm" className={styles.textColor}>
            {t(`jsonViewerPage.dataQuality.${fieldStatus}.label`, {
              count: fieldAnalysis.length,
            })}
          </Text>
        </SigTag>
        <Space size="sm" />
        <Text
          block
          className={cx(styles.textColor, styles.dataQualityDescription)}
          size="sm"
        >
          {t(`jsonViewerPage.dataQuality.${fieldStatus}.description`)}
        </Text>
        <ul>
          {fieldAnalysis.map((field) => (
            <li
              className={styles.datafield}
              key={field.issuePath}
              data-test-id={`missingFieldItem-${field.issuePath}`}
            >
              {field.issuePath}
              {field.fieldDescription && (
                <Tooltip
                  placement="top"
                  title={field.fieldDescription}
                  overlayClassName={styles.tooltip}
                  destroyTooltipOnHide
                >
                  <span
                    className={styles.tooltipIcon}
                    data-test-id={`${field.issuePath}-tooltip`}
                  >
                    <QuestionCircleIcon />
                  </span>
                </Tooltip>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ApiDataQuality
