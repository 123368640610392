import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { TEAM_MODE, UserTeam, createTeam } from '@signifyd/http'

const { t } = i18nInstance
const teamMode: Record<string, TEAM_MODE> = { LIVE: 'LIVE', BURN_IN: 'BURN_IN' }

interface CreateUserTeamsArgs {
  teamName: string
}

const useCreateUserTeam = (): UseMutationResult<
  UserTeam,
  AxiosError,
  CreateUserTeamsArgs
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ teamName }: CreateUserTeamsArgs) => {
      const { data } = await createTeam({
        teamName,
        isTestTeam: true,
        teamMode: teamMode.LIVE,
      })

      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['userTeams'])
    },
    onError: (_, { teamName }) => {
      message.error(t('createTeamPage.errorMessage', teamName))
    },
  })
}

export default useCreateUserTeam
