import { ReloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './RefreshButton.less'

interface Props {
  handleRefresh: () => void
}

const RefreshButton: FC<Props> = ({ handleRefresh }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.refreshButton}>
      <Button
        className={styles.button}
        data-test-id="refreshButton"
        onClick={handleRefresh}
      >
        <ReloadOutlined />
        {t('jsonViewerPage.refreshButton')}
      </Button>
    </div>
  )
}

export default RefreshButton
