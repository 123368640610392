import { Form as AntForm } from '@ant-design/compatible'
import { FormProps } from '@ant-design/compatible/lib/form'
import { FC } from 'react'

const Form: FC<FormProps> = (props) => {
  return (
    <AntForm
      colon={false}
      onKeyDown={(e) => {
        // Stop propagating keydown event to ant Tabs/rc-tabs.
        // rc-tabs calls e.preventDefault on keydown, which will prevent left
        // and right arrow keys from working properly in other components.
        e.stopPropagation()
      }}
      {...props}
    />
  )
}

export default Form
