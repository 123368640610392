import { i18nInstance } from '@signifyd/components'
import {
  ApiSummaryResponse,
  EventAnalysisGroup,
  EventAnalysisGroupApi,
} from '@signifyd/http'
import { WebhookEvent } from 'core/utils/apiAnalysis/utils'

export type ApiCallSelectorOption = { value: string; label: string }
type ApiCallSelectorOptions = Array<ApiCallSelectorOption>

export const getApiCallSelectorOptions = (
  storedCount: number,
  totalEvents: number
): ApiCallSelectorOptions => {
  const options: ApiCallSelectorOptions = []

  let storedCountAux = storedCount

  if (storedCount > totalEvents) {
    storedCountAux = totalEvents
  }

  for (let i = totalEvents; i > totalEvents - storedCountAux; i--) {
    options.push({
      value: String(i),
      label: i18nInstance.t('jsonViewerPage.callNumber', {
        callNumber: i,
      }),
    })
  }

  return options
}

interface GetSelectApiEventArgs {
  data?: Array<EventAnalysisGroup>
  apiName?: string
}

export const getMatchingApiEvent = ({
  data,
  apiName,
}: GetSelectApiEventArgs): EventAnalysisGroupApi | undefined => {
  if (!apiName || !data) {
    return undefined
  }

  return data
    .flatMap((group) => group.apis)
    .find((api) => api.apiName === apiName)
}

interface GetSelectWebhookEventArgs {
  data?: ApiSummaryResponse['webhooks']
  topic: string
  index: number
}

export const getSelectWebhookEvent = ({
  data,
  topic,
  index,
}: GetSelectWebhookEventArgs): WebhookEvent | undefined => {
  if (!data) {
    return undefined
  }

  const selectedTopic = data.find((webhook) => webhook.webhookTopic === topic)

  if (!selectedTopic?.webhookSummaryByUrl[index]) {
    return undefined
  }

  return {
    ...selectedTopic.webhookSummaryByUrl[index],
    webhookTopicLabel: selectedTopic.webhookTopicLabel,
    destinationUrlIndex: index,
  }
}

export const getApiLabel = (
  apiName?: string | null,
  data?: ApiSummaryResponse
): string => {
  if (!apiName || !data?.eventAnalysisGroups?.length) {
    return i18nInstance.t('jsonViewerPage.loading')
  }

  return (
    data.eventAnalysisGroups
      .flatMap((group) => group.apis)
      .find((api) => api.apiName === apiName)?.apiLabel ??
    i18nInstance.t('jsonViewerPage.loading')
  )
}
