import { FC, useEffect } from 'react'
import { useStoreActions, useStoreState } from 'stores'
import { PageSpinner } from '@signifyd/components'

const UserLoader: FC = ({ children }) => {
  const { getCurrentUser } = useStoreActions((store) => store.user)
  const { currentUser, userConfigs } = useStoreState((store) => store.user)

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  if (!currentUser || !userConfigs) {
    return <PageSpinner />
  }

  return <>{children}</>
}

export default UserLoader
