import { ReloadOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './RefreshButton.less'

interface Props {
  onRefresh: () => void
  isLoading: boolean
}

const RefreshButton: FC<Props> = ({ onRefresh, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.refreshButton}>
      <button
        className={styles.button}
        data-test-id="refreshButton"
        onClick={onRefresh}
      >
        {isLoading ? (
          <Spin data-test-id="refreshButtonSpinner" size="small" />
        ) : (
          <ReloadOutlined />
        )}

        {t('jsonViewerPage.refreshButton')}
      </button>
    </div>
  )
}

export default RefreshButton
