import { FC } from 'react'
import { useStoreState } from 'stores'
import {
  ENTITY_TYPE,
  EntityTransferSelect,
  EntityTransferSelectThirdGen,
  useIsThirdGen,
} from '@signifyd/components'
import {
  useTablePagination,
  useTableQueryParams,
} from 'pages/DataQualityPage/utils/flexibleSearch'
import { selectFetchFunction, selectSearchFunction } from './TeamFilter.utils'

interface Props {
  validTeams: Array<string>
}

const TeamFilter: FC<Props> = ({ validTeams }: Props) => {
  const currentUser = useStoreState((state) => state.user.currentUser)
  const { setQuery: setTableQuery } = useTableQueryParams()
  const { setQuery: setPaginationQuery } = useTablePagination()
  const isThirdGen = useIsThirdGen()

  if (!isThirdGen) {
    return (
      <EntityTransferSelect
        entityType={ENTITY_TYPE.TEAM}
        selectedKeys={validTeams}
        onSave={(selectedKeys) => {
          setTableQuery({ teamId: selectedKeys })
          setPaginationQuery({ total: undefined, current: undefined })
        }}
        overrideConfig={{
          fetch: selectFetchFunction(currentUser!),
          search: selectSearchFunction(currentUser!),
        }}
      />
    )
  }

  return (
    <EntityTransferSelectThirdGen
      entityType={ENTITY_TYPE.TEAM}
      selectedKeys={validTeams}
      onSave={(selectedKeys) => {
        setTableQuery({ teamId: selectedKeys })
        setPaginationQuery({ total: undefined, current: undefined })
      }}
      overrideConfig={{
        fetch: selectFetchFunction(currentUser!),
        search: selectSearchFunction(currentUser!),
      }}
    />
  )
}

export default TeamFilter
