import { Typography } from 'antd'
import { Space, ExternalLink, TextThirdGen } from '@signifyd/components'
import styles from './ResourcesPageSection.less'

interface Description {
  preLinkText: string
  url: string
  linkText: string
  postLinkText: string
}

interface Props {
  title: string
  subTitle: string
  description: Description
}

const ResourcesPageSectionThirdGen: React.FC<Props> = ({
  title,
  subTitle,
  description,
}) => {
  const { preLinkText, url, linkText, postLinkText } = description

  return (
    <>
      <Typography.Title level={3}>{title}</Typography.Title>
      <Space size="md" />
      <TextThirdGen paragraph className={styles.subTitle}>
        {subTitle}
      </TextThirdGen>
      <Space size="sm" />
      <TextThirdGen paragraph>
        {preLinkText}{' '}
        <ExternalLink url={url} target="_blank">
          {linkText}
        </ExternalLink>{' '}
        {postLinkText}
      </TextThirdGen>
    </>
  )
}

export default ResourcesPageSectionThirdGen
