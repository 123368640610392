import { Loading3QuartersOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { FC } from 'react'
import styles from './LoadingSpinner.less'

interface Props {
  darkTheme?: boolean
  'data-test-id'?: string
}

const LoadingSpinner: FC<Props> = ({
  darkTheme = false,
  'data-test-id': dataTestId = 'loadingSpinner',
}) => (
  <div
    className={cx(styles.wrapper, { [styles.dark]: darkTheme })}
    data-test-id={dataTestId}
  >
    <Loading3QuartersOutlined spin className={styles.icon} />
  </div>
)

export default LoadingSpinner
