import { useQueryParams, DecodedValueMap, SetQuery } from 'use-query-params'
import { SEARCH_FIELD, SEARCH_ORDER } from '@signifyd/http'
import { createEnumParam } from '@signifyd/utils'

const DEFAULT_ORDER_BY = SEARCH_FIELD.normalizedPurchaseCreatedAt
const DEFAULT_SORT = SEARCH_ORDER.DESCENDING

const tableSortingQueryMap = {
  orderBy: createEnumParam(SEARCH_FIELD, DEFAULT_ORDER_BY),
  sortOrder: createEnumParam(SEARCH_ORDER, DEFAULT_SORT),
}

interface UseTableSortingHook {
  query: DecodedValueMap<typeof tableSortingQueryMap>
  setQuery: SetQuery<typeof tableSortingQueryMap>
  resetTableSorting: () => void
}

export const useTableSorting = (): UseTableSortingHook => {
  const [query, setQuery] = useQueryParams(tableSortingQueryMap)

  const resetTableSorting = (): void => {
    setQuery({
      orderBy: DEFAULT_ORDER_BY,
      sortOrder: DEFAULT_SORT,
    })
  }

  return { query, setQuery, resetTableSorting }
}
