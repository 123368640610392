import { Layout } from 'antd'
import { FC, ReactNode } from 'react'
import { useStoreState } from 'stores'
import {
  CustomerNavBar,
  Layout as SigLayout,
  useIsThirdGen,
} from '@signifyd/components'
import ContentWrapper from './ContentWrapper'
import styles from './PageWrapper.less'
import NavigationBar from '../NavigationBar/NavigationBar'

interface Props {
  backgroundColor?: string
  Header?: ReactNode
}

const { Content } = Layout

const PageWrapper: FC<Props> = ({
  Header,
  children,
  backgroundColor = 'white',
}) => {
  const { currentUser: user, userConfigs } = useStoreState(
    (state) => state.user
  )
  const isThirdGen = useIsThirdGen()

  if (!user || !userConfigs) {
    return null
  }

  if (!isThirdGen) {
    return (
      <>
        <SigLayout
          innerMinWidth={null}
          innerMaxWidth={null}
          innerStyle={{ padding: 0 }}
        >
          <SigLayout.NavBar>
            <CustomerNavBar user={user} userConfigs={userConfigs} />
          </SigLayout.NavBar>
          {Header && Header}
          <ContentWrapper backgroundColor={backgroundColor}>
            {children}
          </ContentWrapper>
        </SigLayout>
      </>
    )
  }

  return (
    <Layout className={styles.layout}>
      <NavigationBar user={user} userConfigs={userConfigs} />
      {Header && Header}
      <Content>{children}</Content>
    </Layout>
  )
}

export default PageWrapper
