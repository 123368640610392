import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import styles from './EventsContainer.less'

const NoEventFound: FC = () => {
  const { t } = useTranslation()

  return (
    <Flex justify="center" align="center" className={styles.noEventSelected}>
      <Text block type="secondary" size="lg" weight="semibold">
        {t('jsonViewerPage.noEventFound')}
      </Text>
    </Flex>
  )
}

export default NoEventFound
