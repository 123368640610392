import { ResponsiveBar } from '@nivo/bar'
import { FC } from 'react'
import {
  colorYonder,
  colorMarengo,
  colorGlitter,
  colorSlate,
} from '@signifyd/colors'
import ChangeIndicator from 'pages/DataQualityPage/components/ChangeIndicator'
import { CalculationMethod, TotalResults } from 'pages/DataQualityPage/types'
import {
  calculateValue,
  getFormattedValue,
  getGridValues,
  shouldDisplayDiff,
} from 'pages/DataQualityPage/utils/dataComparison/utils'
import { useDataComparisonQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch/useTableComparisonQueryParams'
import styles from './BarGraph.less'

interface Props {
  valuesPerRange: {
    rangeA: number
    rangeB: number
  }
  calculationMethod: CalculationMethod
  totalResults: TotalResults
}

const BarGraph: FC<Props> = ({
  valuesPerRange,
  calculationMethod,
  totalResults,
}) => {
  const { rangeA: rangeATotal, rangeB: rangeBTotal } = totalResults
  const { rangeA: rangeAValue, rangeB: rangeBValue } = valuesPerRange

  const {
    query: { rangeB },
  } = useDataComparisonQueryParams()

  const calculatedRangeAValue = calculateValue(
    rangeATotal,
    rangeAValue,
    calculationMethod
  )

  const calculatedRangeBValue = calculateValue(
    rangeBTotal,
    rangeBValue,
    calculationMethod
  )

  const graphA = {
    range: 'rangeA',
    value: calculatedRangeAValue,
    formattedValue: getFormattedValue(calculatedRangeAValue, calculationMethod),
  }

  const graphB = {
    range: 'rangeB',
    value: calculatedRangeBValue,
    formattedValue: getFormattedValue(calculatedRangeBValue, calculationMethod),
  }

  const gridValues = getGridValues(calculationMethod, totalResults)

  const combinedData = [graphA]

  if (rangeB.normalizedPurchaseCreatedAt) {
    combinedData.push(graphB)
  }

  return (
    <>
      <div className={styles.lineGraphContainer}>
        <ResponsiveBar
          theme={{
            text: { fontSize: 15 },
            axis: {
              legend: { text: { fontSize: 11 } },
              ticks: { text: { fontSize: 11 } },
            },
          }}
          key={calculationMethod}
          data={combinedData}
          margin={{ top: 22, left: 50, bottom: 20 }}
          keys={['value']}
          padding={0}
          indexBy="range"
          enableLabel={false}
          valueFormat={(value) => getFormattedValue(value, calculationMethod)}
          enableTotals
          labelTextColor={colorMarengo}
          innerPadding={0.2}
          borderWidth={1}
          borderColor={colorSlate}
          isInteractive={false}
          colors={({ data }) =>
            data.range === 'rangeA' ? colorGlitter : colorYonder
          }
          axisBottom={null}
          gridYValues={gridValues}
          axisLeft={{
            tickValues: gridValues,
            tickSize: 3,
            tickRotation: 0,
          }}
          indexScale={{ type: 'band', round: true }}
          maxValue={gridValues.at(-1)}
          minValue={0}
        />
      </div>
      <p className={styles.thresholdPercentage}>
        {!!rangeB.normalizedPurchaseCreatedAt &&
          shouldDisplayDiff(graphB.value, graphA.value) && (
            <ChangeIndicator
              valueA={graphA.value}
              valueB={graphB.value}
              calculationMethod={calculationMethod}
            />
          )}
      </p>
    </>
  )
}

export default BarGraph
