import { Tooltip } from 'antd'
import { startCase, toLower } from 'lodash'
import { FC } from 'react'
import { SigTag } from '@signifyd/components'
import { UserTeam } from '@signifyd/http'

type TagColors = 'slate' | 'jade' | 'gold'

export const getTagDetails = ({
  test,
  teamMode,
}: Pick<UserTeam, 'teamMode' | 'test'>): {
  color: TagColors
  text: string
  tooltipBody: string
} => {
  return test
    ? {
        color: 'slate',
        text: startCase(toLower(teamMode)),
        tooltipBody:
          'This team is a self-contained sandbox for creating and testing your integration. You will not be charged for orders created on this team.',
      }
    : {
        color: teamMode === 'LIVE' ? 'jade' : 'gold',
        text: startCase(toLower(teamMode)),
        tooltipBody:
          teamMode === 'LIVE'
            ? 'This team is live and all orders are protected by Signifyd’s financial guarantee.'
            : 'This team is currently being reviewed by Signifyd. When your integration has been reviewed and is ready to go live, the status will be automatically updated to live.',
      }
}

interface Props {
  team: UserTeam
}

const TeamStatusTag: FC<Props> = ({ team }) => {
  const tagDetails = getTagDetails(team)

  return (
    <Tooltip overlay={<span>{tagDetails.tooltipBody}</span>}>
      <SigTag type="secondary" color={tagDetails.color}>
        {tagDetails.text}
      </SigTag>
    </Tooltip>
  )
}

export default TeamStatusTag
